import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { connect } from 'react-redux';
// import { useHistory } from 'react-router-dom';
// import Swal from 'sweetalert2';

// import { IcModalLogout } from '../../assets';
import { logout } from '../../store/actions/authAction';
// import { getDetailUser, getNotif, readNotif } from '../../store/actions/userAction';
import MobileNavigation from '../navigation/MobileNavigation';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minHeight: '90vh',
        maxWidth: '100%',
    },
    appBar: {
        // zIndex: theme.zIndex.drawer + 1,
        backgroundColor: 'var(--primary)'
    },
    drawer: {
        // width: drawerWidth,
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    drawerPaper: {
        // width: drawerWidth,
        border: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    drawerContainer: {
        overflow: 'auto',
        paddingLeft: 20,
        paddingRight: 20
    },
    content: {
        flexGrow: 1
    },
    imgProfile: {
        width: 40,
        height: 40,
        borderRadius: 40,
        objectFit: 'cover',
        background: '#EBEAEA'
    },
    textName: {
        fontSize: 16,
        color: '#262F56'
    },
    textUserName: {
        fontSize: 12,
        color: '#262F56'
    },
    userInfo: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    }
}));

const Sidebar = ({
    children,
    user,
    // getDetailUser,
    logout,
    notif,
    // getNotif,
    readNotif,
    isHome,
    noNavbar
}) => {
    const classes = useStyles();
    // const history = useHistory();
    // const [path, setPath] = useState('');

    // useEffect(
    //     () => {
    //         const path = window.location.pathname;
    //         setPath(path);
    //         // getDetailUser();
    //         // getNotif();
    //     },
    //     [
    //         // getDetailUser, getNotif
    //     ]
    // );

    // const handleLogout = () => {
    //     Swal.fire({
    //         text: 'Are you sure want to logout?',
    //         imageUrl: IcModalLogout,
    //         imageWidth: 92,
    //         imageHeight: 92,
    //         imageAlt: 'icon logout',
    //         showCancelButton: true,
    //         cancelButtonText: 'No',
    //         confirmButtonText: 'Yes',
    //         confirmButtonColor: '#EBEAEA',
    //         cancelButtonColor: '#00ACEE'
    //     }).then((res) => {
    //         if (res.isConfirmed) {
    //             logout().then(() => history.push('/login'));
    //         }
    //     });
    // };

    return (
        <>
            <div className={classes.root}>
                <CssBaseline />
                {/* <AppBar
                    position="fixed"
                    className={classes.appBar}
                    elevation={0}
                >
                    <div className="d-flex align-items-center justify-content-between py-2 container">
                        
                    </div>
                </AppBar> */}
                <main
                    className={classes.content}
                    style={isHome ? { background: 'var(--primary)',maxWidth: "100%" } : {maxWidth: "100%" }}
                >
                    <Toolbar />
                    {children}
                </main>
            </div>
            {noNavbar ? null : <MobileNavigation />}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        notif: state?.user?.notif
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // getDetailUser: () => dispatch(getDetailUser()),
        logout: () => dispatch(logout())
        // getNotif: () => dispatch(getNotif()),
        // readNotif: () => dispatch(readNotif())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
