import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { Chip } from '@mui/material';

import Title from '../../components/Title/Title';
import { ModalTandaTangan } from '../../components';
import '../../assets/style/perjanjianKerja.css';
import { IcFrame } from '../../assets/icons';
import {
    cetakKontrakKerja,
    ajukanPerjanjianKerja,
    getTandaTangan
} from '../../store/actions/perjanjianKerjaAction';

import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { SizeMe } from 'react-sizeme';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PerjanjianKerja = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const refIframe = useRef(null);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const [state, setState] = useState({
        pdf_path: '',
        tanda_tangan: '',
        status: ''
    });

    function ajukan() {
        dispatch(ajukanPerjanjianKerja())
            .then(() => {
                Swal.fire(
                    'Success!',
                    'Permintaan untuk diajukan berhasil!',
                    'success'
                );
                getData();
            })
            .catch((err) => {
                let error = err?.response?.message;
                Swal.fire(
                    Array.isArray(error?.message)
                        ? error?.message
                        : 'Gagal mengajukan, coba beberapa saat lagi',
                    false
                );
            });
    }

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => {
        setOpenModal(!openModal);
    };

    const getData = () => {
        cetakKontrakKerja().then((res) => {
            let data = res.data.data;
            setState((prev) => ({
                ...prev,
                ...data
            }));
        });
        getTandaTangan().then((res) => {
            console.log(res.data.data);
            let data = res.data.data;
            if (res.data.code === 0) {
                setState((prev) => ({
                    ...prev,
                    ...data
                }));
            }
        });
    };

    useLayoutEffect(() => {
        getData();
    }, []);

    // useEffect(() => {
    //     refIframe.current.setAttribute('src', state.pdf_path + '#zoom=FitH');
    // }, []);

    console.log(state);

    const title = (
        <div className="d-flex justify-content-between pr-18">
            <div onClick={() => history.push('/akun')} className="d-flex">
                <i className="ri-arrow-left-line"></i>
                <p className="mx-2">Perjanjian Kerja</p>
            </div>
            <div
                onClick={() =>
                    history.push({
                        pathname: '/pdf_link_perjanjian_kerja',
                        state: { url: state.pdf_path }
                    })
                }
                className="d-flex"
            >
                <img
                    src={IcFrame}
                    className="img-thumbnail-sm"
                    width="100%"
                    height="auto"
                    alt="frame"
                ></img>
            </div>
        </div>
    );

    return (
        <div className="i-ultra-container mx-auto p-0">
            <Title title={title} />

            {state.status === 'diproses' && (
                <Chip
                    sx={{
                        height: 'auto',
                        width: '250px',
                        backgroundColor: 'white',
                        zIndex: 'tooltip',
                        position: 'fixed',
                        top: '80px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        '& .MuiChip-label': {
                            display: 'block',
                            paddingBlock: '7px',
                            fontSize: '12px',
                            fontWeight: 600,
                            whiteSpace: 'normal'
                        }
                    }}
                    label="Pengajuan Diproses"
                    variant="outlined"
                    color="warning"
                />
            )}
            {state.status === 'diterima' && (
                <Chip
                    sx={{
                        height: 'auto',
                        width: '250px',
                        backgroundColor: 'white',
                        zIndex: 'tooltip',
                        position: 'fixed',
                        top: '80px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        '& .MuiChip-label': {
                            display: 'block',
                            paddingBlock: '7px',
                            fontSize: '12px',
                            fontWeight: 600,
                            whiteSpace: 'normal'
                        }
                    }}
                    label="Pengajuan Disetujui"
                    variant="outlined"
                    color="success"
                />
            )}
            {state.status === 'ditolak' && (
                <Chip
                    sx={{
                        height: 'auto',
                        width: '250px',
                        backgroundColor: 'white',
                        zIndex: 'tooltip',
                        position: 'fixed',
                        top: '80px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        '& .MuiChip-label': {
                            display: 'block',
                            paddingBlock: '7px',
                            fontSize: '12px',
                            fontWeight: 600,
                            whiteSpace: 'normal'
                        }
                    }}
                    label="Pengajuan Ditolak"
                    variant="outlined"
                    color="error"
                />
            )}

            {/* <iframe
                type="application/pdf"
                key={state.pdf_path + Math.random() * 1000000}
                src=
                title="title"
                className={`min-h-screen i-ultra-container ${
                    state.status === '' && 'mb-65'
                }`}
                width="100%"
                sandbox="allow-same-origin allow-scripts allow-forms"
                ref={refIframe}
            /> */}
            <SizeMe
            // monitorHeight
            // refreshRate={128}
            // refreshMode={'debounce'}
            >
                {({ size }) => (
                    <Document
                        key={state.pdf_path + Math.random() * 1000000}
                        // file={{
                        //     uri: state.pdf_path + '#view=FitH',
                        //     method: 'GET',
                        //     headers: { 'Cache-Control': 'no-cache' },
                        //     body: ''
                        // }}
                        //file={await fetch(state.pdf_path, {
                        //    method: 'GET',
                            // mode: 'cors', // no-cors, *cors, same-origin
                            //cache: 'no-cache',
                            // credentials: 'same-origin', // include, *same-origin, omit
                        //    headers: { 'Cache-Control': 'no-cache' },
                            //redirect: 'follow', // manual, *follow, error
                            //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                        //}).then((data) => data)}
                        file={state.pdf_path}
                        // file="https://prod-assignments.s3.ap-south-1.amazonaws.com/student/mgmt11learn156/23558/1633438144000.pdf#view=FitH"
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={console.error}
                        // options={{ workerSrc: '/pdf.worker.js' }}
                        // pageLayout="oneColumn"
                    >
                        <Page
                            width={size.width ? size.width : 1}
                            pageNumber={pageNumber}
                        />
                        {/* {Array.from(new Array(numPages), (el, index) => (
<Page
    className="mx-auto"
    key={`page_${index + 1}`}
    pageNumber={index + 1}
    width=""
    // style={{ width: 'fit-content' }}
/>
))} */}
                    </Document>
                )}
            </SizeMe>

            {(state.status === '' || state.status === 'ditolak') && (
                <div className="container fixed-bottom py-20 i-ultra-container bg-white mt-0">
                    {state.tanda_tangan === '' ? (
                        <div className="row">
                            <button
                                type="button"
                                className="w-100 fs-12 col mx-2 ml-3 py-8px rounded-6 bg-white border border-warning text-warning"
                                onClick={handleOpenModal}
                            >
                                Buat Tanda Tangan
                            </button>
                            <button
                                type="button"
                                className="w-100 fs-12 col mx-2 mr-3 py-8px rounded-6 bg-white border border-secondary text-secondary"
                            >
                                Ajukan Surat
                            </button>
                        </div>
                    ) : (
                        <div className="row">
                            <button
                                type="button"
                                className="w-100 fs-12 col mx-2 ml-3 py-8px rounded-6 bg-white border border-warning text-warning"
                                onClick={handleOpenModal}
                            >
                                Edit Tanda Tangan
                            </button>
                            <button
                                type="button"
                                className="w-100 fs-12 col mx-2 mr-3 py-8px rounded-6 bg-white border-blue text-blue"
                                onClick={ajukan}
                            >
                                Ajukan Surat
                            </button>
                        </div>
                    )}
                </div>
            )}
            <ModalTandaTangan
                open={openModal}
                getData={getData}
                handleClose={handleOpenModal}
            />
        </div>
    );
};

export default PerjanjianKerja;
