import { Select, FormControl, MenuItem, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export default function AbsensiSelect({
    dataSelect = [],
    state = '',
    required = false,
    setState = '',
    label = '',
    width = '100%',
    name = ''
}) {
    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    };

    const useStyles = makeStyles((theme) => ({
        inputLabel: {
            color: '#555c64b7 !important',
            fontSize: '15px !important',
            backgroundColor: 'white !important'
        }
    }));

    const classes = useStyles();
    return (
        <div>
            <FormControl
                variant="outlined"
                style={{ width: width }}
                required={required}
                size="small"
            >
                <InputLabel className={classes.inputLabel}>{label}</InputLabel>
                <Select
                    IconComponent={() => (
                        <KeyboardArrowDownIcon
                            style={{ marginRight: '16px' }}
                        />
                    )}
                    inputProps={{ name }}
                    onChange={handleChange}
                    value={state[name]}
                    size="small"
                    style={{ backgroundColor: '#fbfbfb' }}
                >
                    {dataSelect.map((data) => (
                        <MenuItem
                            key={data.value}
                            value={data.value}
                            text={data.text}
                        >
                            {data.text}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
