import { LOGIN } from '../constant';

const initialState = {
    authenticated: false,
    user: null
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${LOGIN}_PENDING`:
            return {
                ...state,
                authenticated: false,
                user: null
            };
        case `${LOGIN}_FULFILLED`:
            return {
                ...state,
                authenticated: action.payload.authenticated,
                user: action.payload.user
            };
        case `${LOGIN}_REJECTED`:
            return {
                ...state,
                authenticated: false,
                user: null
            };

        default:
            return state;
    }
};

export default authReducer;
