import { GET_ATURAN_KEBIJAKAN, GET_FAQ } from '../constant';

const initialState = {
    detailKaryawan: [],
    faq: [],
    aturan: [],
    kebijakan: []
};

const akunReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FAQ: {
            return {
                ...state,
                faq: action.payload
            };
        }
        case GET_ATURAN_KEBIJAKAN: {
            return {
                ...state,
                aturan: action.aturan,
                kebijakan: action.kebijakan
            };
        }
        default: {
            return state;
        }
    }
};

export default akunReducer;

// {{host}}/akun/faq

// {{host}}/akun/aturan_kebijakan
