import React, { useEffect, useState, useRef } from 'react';
import { Sidebar, Title } from '../../components';
import '../../assets/style/inputabsensi.css';
import { useDispatch } from 'react-redux';

import { IcBarcode, IcGpsPoint, IcCamera, IcChangeCamera } from '../../assets';
import {
    addKaryawanAbsensi,
    editKaryawanAbsensi
} from '../../store/actions/absenAction';
import Swal from 'sweetalert2';
import { Button, CircularProgress } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { getCurrentTime } from '../../utils/currentTime';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

export default function InputAbsensiSelfie() {
    const { id, code } = useParams();
    const location = useLocation();
    const waktuMasuk = location.state?.waktuMasuk;
    const [data, setData] = useState('Not Found');

    const dispatch = useDispatch();
    const [submit, setSubmit] = useState(false);
    const [photo, setPhoto] = useState('asd');

    const [display, setDisplay] = useState(false);
    const [photoUrl, setPhotoUrl] = useState('');

    const videoRef = useRef(null);
    const photoRef = useRef(null);
    const [facingMode, setFacingMode] = useState('user');

    const takePicture = () => {
        const width = 640;
        const height = 480;
        const photo = photoRef.current;
        let video = videoRef.current;
        const context = photo.getContext('2d');

        photo.width = width;
        photo.height = height;

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                context.drawImage(video, 0, 0, photo.width, photo.height);
                resolve(photo.toDataURL('image/jpg'));
            }, 100);
        });
    };

    const handleTakePicture = async () => {
        if (photoRef.current) {
            const photoUrl = await takePicture();
            setPhotoUrl(photoUrl);
            setDisplay(true);
            const blob = await fetch(photoUrl).then((r) => r.blob());
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64String = reader.result;
                setPhoto(base64String);
            };
        }
    };

    const toggleFacingMode = () => {
        let video = videoRef.current;
        let facingMode = video.facingMode;
        facingMode = facingMode === 'user' ? 'environment' : 'user';

        const constraints = {
            video: { facingMode: facingMode }
        };

        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
            video.srcObject = stream;
        });
    };

    const [isStreamRunning, setIsStreamRunning] = useState(false);

    useEffect(() => {
        let stream = null;

        if (!display) {
            const getUserMedia = async () => {
                try {
                    stream = await navigator.mediaDevices.getUserMedia({
                        video: true
                    });
                    if (videoRef.current) {
                        videoRef.current.srcObject = stream;
                        videoRef.current.play();
                    }
                } catch (err) {
                    console.log(err);
                }
            };

            getUserMedia();
        }

        return () => {
            if (stream) {
                const tracks = stream.getTracks();
                tracks.forEach((track) => track.stop());
            }
        };
    }, [display]);
    const history = useHistory();

    const handleSubmit = async () => {
        try {
            let response;
            setSubmit(true);

            const formData = new FormData();
            if (id === 'Masuk') {
                formData.append('foto_bukti', photo);
            } else if (id === 'Keluar') {
                formData.append('foto_bukti_pulang', photo);
                formData.append('absensi_karyawan_code', code);
            }

            switch (id) {
                case 'Masuk':
                    response = await dispatch(addKaryawanAbsensi(formData));
                    break;
                case 'Keluar':
                    response = await dispatch(editKaryawanAbsensi(formData));
                    break;
                default:
                    throw new Error(`Invalid id: ${id}`);
            }
            Swal.fire({
                title: 'Success',
                text: `Data Absensi Berhasil Disimpan`,
                icon: 'success',
                iconColor: '#00ACEE',
                timer: 2000,
                showConfirmButton: false
            }).then(() => {
                history.push('/');

                setSubmit(false);
            });
        } catch (error) {
            const err = error.response.data;
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                timer: 2000,
                showConfirmButton: false
            });
            setSubmit(false);
        }
    };

    return (
        <div>
            <Sidebar noNavbar={true}>
                <Title
                    style={{ height: '10vh' }}
                    title={
                        id === 'Masuk_Lembur'
                            ? 'Absen Masuk Lembur'
                            : id === 'Keluar_Lembur'
                            ? 'Absen Keluar  Lembur'
                            : `Absen ${id}`
                    }
                    back
                ></Title>
                <canvas ref={photoRef} style={{ display: 'none' }} />

                {display ? (
                    <div>
                        <img
                            style={{ objectFit: 'cover', minHeight: '70vh' }}
                            src={photoUrl}
                            alt="photo url"
                        />

                        <div className="change-camera-button-retake">
                            <Button onClick={() => setDisplay(false)}>
                                <img src={IcChangeCamera} alt="changecamera" />
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div>
                        <video
                            style={{
                                width: '100vw',
                                height: '90vh',
                                objectFit: 'cover',
                                position: 'absolute',
                                bottom: '0'
                            }}
                            ref={videoRef}
                            autoPlay
                        />

                        <div className="take-picture-button">
                            <Button
                                onClick={() => {
                                    handleTakePicture();
                                }}
                            >
                                <img src={IcCamera} alt="camera" />
                            </Button>
                        </div>
                        <div className="change-camera-button">
                            <Button onClick={toggleFacingMode}>
                                <img src={IcChangeCamera} alt="changecamera" />
                            </Button>
                        </div>
                    </div>
                )}

                {display && (
                    <div className="input-absensi-footer">
                        <div className="input-absensi-footer-Button">
                            <Button
                                color="primary"
                                style={{ color: 'white' }}
                                type="submit"
                                variant="contained"
                                className="mb-4 w-100 py-2 btn-auth"
                                disabled={submit}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                {submit ? (
                                    <CircularProgress
                                        size={24}
                                        className="text-salmon"
                                    />
                                ) : id === 'Masuk_Lembur' ? (
                                    'Absen Masuk Lembur'
                                ) : id === 'Keluar_Lembur' ? (
                                    'Absen Keluar  Lembur'
                                ) : (
                                    `Absen ${id}`
                                )}
                            </Button>
                        </div>
                        <div className="absensi-footer-bottom">
                            <div className="absensi-input-card ">
                                <div>
                                    <h2>Jam Masuk</h2>
                                    <h1>
                                        {id === 'Masuk'
                                            ? getCurrentTime()
                                            : waktuMasuk?.substring(0, 5)}
                                    </h1>
                                </div>
                                <div className="w-1px  h-50px  bg-grey-border br-2  "></div>
                                <div>
                                    <h2>Jam Keluar</h2>
                                    <h1>
                                        {id === 'Masuk'
                                            ? '--:--'
                                            : getCurrentTime()}
                                    </h1>
                                </div>
                            </div>
                            <div className="w-1px  h-50px  bg-grey-border br-2  "></div>

                            <div className="input-absensi-button-wrapper">
                                <Link
                                    to={
                                        code
                                            ? {
                                                  pathname: `/InputAbsensi/${id}/${code}`,
                                                  state: { waktuMasuk }
                                              }
                                            : `/InputAbsensi/${id}`
                                    }
                                >
                                    <div className="input-absensi-button-container">
                                        <div className="input-absensi-button">
                                            <img src={IcGpsPoint} alt="" />
                                        </div>
                                        map
                                    </div>
                                </Link>
                                <Link
                                    to={
                                        code
                                            ? {
                                                  pathname: `/InputAbsensi_qr/${id}/${code}`,
                                                  state: { waktuMasuk }
                                              }
                                            : `/InputAbsensi_qr/${id}`
                                    }
                                >
                                    <div className="input-absensi-button-container">
                                        <div className="input-absensi-button">
                                            <img src={IcBarcode} alt="" />
                                        </div>
                                        Barcode
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
            </Sidebar>
        </div>
    );
}
