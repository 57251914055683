import React, { useState } from 'react';
import { Sidebar, Title } from '../../components';
import '../../assets/style/inputabsensi.css';
import BarcodeScannerComponent from 'react-qr-barcode-scanner-17';
import 'leaflet/dist/leaflet.css';
import { useDispatch } from 'react-redux';
import L from 'leaflet';
import iconMarker from 'leaflet/dist/images/marker-icon.png';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { IcSelfie, IcGpsPoint } from '../../assets';
import {
    addKaryawanAbsensi,
    editKaryawanAbsensi
} from '../../store/actions/absenAction';
import Swal from 'sweetalert2';
import { CircularProgress, Button } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Intersect } from '../../assets';
import { getCurrentTime } from '../../utils/currentTime';
import { useLocation } from 'react-router-dom';

export default function InputAbsensiQR() {
    const { id, code } = useParams();
    const location = useLocation();
    const waktuMasuk = location.state?.waktuMasuk;
    const [data, setData] = useState('');
    const icon = L.icon({
        iconRetinaUrl: iconRetina,
        iconUrl: iconMarker,
        shadowUrl: iconShadow
    });

    const dispatch = useDispatch();
    const [submit, setSubmit] = useState(false);
    const history = useHistory();
    const [scanning, setScanning] = useState(false);

    console.log(data.text);

    const handleSubmit = async (data) => {
        if (scanning === false) {
            await setScanning(true);
            await setSubmit(true);

            try {
                let response;

                switch (id) {
                    case 'Masuk':
                        response = await dispatch(
                            addKaryawanAbsensi({
                                qr_code: data.text
                            })
                        );
                        break;
                    case 'Keluar':
                        response = await dispatch(
                            editKaryawanAbsensi({
                                absensi_karyawan_code: code,
                                qr_code: data.text
                            })
                        );
                        break;
                    default:
                        throw new Error(`Invalid id: ${id}`);
                }
                Swal.fire({
                    title: 'Success',
                    text: `Data Absensi Berhasil Disimpan`,
                    icon: 'success',
                    iconColor: '#00ACEE',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    history.push('/');
                    setSubmit(false);
                });
            } catch (error) {
                const err = error.response.data;
                Swal.fire({
                    title: 'Error',
                    text: 'The selected qr code is invalid',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false
                });
                setSubmit(false);
                setScanning(false);
            }
        }
    };

    const ScreenWidth = window.innerWidth;
    console.log(scanning);

    // useEffect(() => {
    //     setTimeout(() =>
    //     }, 1500);
    // }, [data]);

    return (
        <div>
            <Sidebar noNavbar={true}>
                <Title
                    style={{ height: '10vh' }}
                    back
                    title={
                        id === 'Masuk_Lembur'
                            ? 'Absen Masuk Lembur'
                            : id === 'Keluar_Lembur'
                            ? 'Absen Keluar  Lembur'
                            : `Absen ${id}`
                    }
                ></Title>

                <div className="barcode-scanner">
                    <img
                        className="intersect1"
                        src={Intersect}
                        alt="intersect"
                    />
                    <img
                        className="intersect2"
                        src={Intersect}
                        alt="intersect"
                    />
                    <img
                        className="intersect3"
                        src={Intersect}
                        alt="intersect"
                    />
                    <img
                        className="intersect4"
                        src={Intersect}
                        alt="intersect"
                    />
                    <div className="qr-background">
                        <h2>Scan Disini</h2>
                        <h5>
                            Posisikan barcode <br /> didalam kotak
                        </h5>
                    </div>
                    <BarcodeScannerComponent
                        width={ScreenWidth}
                        height={600}
                        delay={5000}
                        onUpdate={(err, result) => {
                            if (result && !scanning) {
                                handleSubmit(result);
                            }
                        }}
                        onError={(err) => {
                            Swal.fire({
                                title: 'Error',
                                text: 'Coba Scan Lagi',
                                icon: 'error',
                                timer: 2000,
                                showConfirmButton: false
                            });
                        }}
                    />
                </div>

                <div className="input-absensi-footer">
                    <div className="input-absensi-footer-Button">
                        <Button
                            color="primary"
                            style={{ color: 'white' }}
                            type="submit"
                            variant="contained"
                            className="mb-4 w-100 py-2 btn-auth"
                            disabled={submit}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            {submit ? (
                                <CircularProgress
                                    size={24}
                                    className="text-salmon"
                                />
                            ) : id === 'Masuk_Lembur' ? (
                                'Absen Masuk Lembur'
                            ) : id === 'Keluar_Lembur' ? (
                                'Absen Keluar  Lembur'
                            ) : (
                                `Absen ${id}`
                            )}
                        </Button>
                    </div>
                    <div className="absensi-footer-bottom">
                        <div className="absensi-input-card ">
                            <div>
                                <h2>Jam Masuk</h2>
                                <h1>
                                    {id === 'Masuk'
                                        ? getCurrentTime()
                                        : waktuMasuk?.substring(0, 5)}
                                </h1>
                            </div>
                            <div className="w-1px  h-50px  bg-grey-border br-2  "></div>
                            <div>
                                <h2>Jam Keluar</h2>
                                <h1>
                                    {id === 'Masuk'
                                        ? '--:--'
                                        : getCurrentTime()}
                                </h1>
                            </div>
                        </div>
                        <div className="w-1px  h-50px  bg-grey-border br-2  "></div>

                        <div className="input-absensi-button-wrapper">
                            <Link
                                to={
                                    code
                                        ? {
                                              pathname: `/InputAbsensi/${id}/${code}`,
                                              state: { waktuMasuk }
                                          }
                                        : `/InputAbsensi/${id}`
                                }
                            >
                                <div className="input-absensi-button-container">
                                    <div className="input-absensi-button">
                                        <img src={IcGpsPoint} alt="" />
                                    </div>
                                    map
                                </div>
                            </Link>
                            <Link
                                to={
                                    code
                                        ? {
                                              pathname: `/InputAbsensi_selfie/${id}/${code}`,
                                              state: { waktuMasuk }
                                          }
                                        : `/InputAbsensi_selfie/${id}`
                                }
                            >
                                <div className="input-absensi-button-container">
                                    <div className="input-absensi-button">
                                        <img src={IcSelfie} alt="" />
                                    </div>
                                    selfie
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </Sidebar>
        </div>
    );
}
