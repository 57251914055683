import { GET_NOTIFIKASI, GET_PERINGATAN } from '../actions/notifikasiAction';
const initialState = {
    dataNotifikasi: [],
    dataPeringatan: {}
};

const notifikasiReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTIFIKASI:
            return {
                ...state,
                dataNotifikasi: action.payload
            };
        case GET_PERINGATAN:
            return {
                ...state,
                dataPeringatan: action.payload
            };

        default:
            return state;
    }
};

export default notifikasiReducer;
