import React from 'react';
import '../../assets/style/mobile-size.css';
import { IcTitleIcon } from '../../assets';
import { useHistory } from 'react-router-dom';

const Title = ({
    title,
    icon,
    subtitle,
    handleIcon,
    back,
    backLink = '/Absensi',
    noBackLink,
}) => {
    const history = useHistory();

    return (
        <div className=" fixed-top  z-100 ">
        <div className="bg-white p-title text-black  title-container">
            <div
                className="d-flex align-items-center"
                style={{
                    width: 'fit-content',
                    gap: '15px'
                }}
            >
                {back && (
                    <i
                        onClick={
                            noBackLink
                                ? () => history.goBack()
                                : () => history.push(backLink)
                        }
                        className="ri-arrow-left-line"
                    ></i>
                )}

                <p
                    className="font-weight-semibold fs-16"
                    style={{ margin: '0' }}
                >
                    {title}
                </p>
            </div>
            {icon && <img src={IcTitleIcon} alt="icon" onClick={handleIcon} />}
        </div>
        { subtitle}
        </div>
    );
};

export default Title;
