export const LOGIN = 'LOGIN';
export const SEND_FORGOT = 'SEND_FORGOT';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const LOGOUT = 'LOGOUT';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const DETAIL_USER = 'DETAIL_USER';
export const DETAIL_USER_CODE = 'DETAIL_USER_CODE';
export const CHECK_TOKEN_RESET = 'CHECK_TOKEN_RESET';
export const DETAIL_USERNAME = 'DETAIL_USERNAME';
export const GET_NOTIF = 'GET_NOTIF';
export const READ_NOTIF = 'READ_NOTIF';

export const GET_DATA_IZIN = 'GET_DATA_IZIN';
export const ADD_DATA_IZIN = 'ADD_DATA_IZIN';
export const EDIT_DATA_IZIN = 'EDIT_DATA_IZIN';
export const GET_IZIN_USER = 'GET_IZIN_USER';
export const GET_LIST_JENIS_IZIN = 'GET_LIST_JENIS_IZIN';
export const GET_CUTI_USER = 'GET_CUTI_USER';
export const GET_CUTI_QUANTITY = 'GET_CUTI_QUANTITY';
export const GET_DETAIL_CUTI = 'GET_DETAIL_CUTI';

export const GET_LIST_GAJI = 'GET_LIST_GAJI';

export const GET_FAQ = 'GET_FAQ';
export const GET_ATURAN_KEBIJAKAN = 'GET_ATURAN_KEBIJAKAN';
