import { createBrowserHistory } from 'history';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoutes from '../utils/PrivateRoutes';

import UnduhSlip from '../pages/Gaji/UnduhSlip';
import {
    Forgot,
    Home,
    Login,
    NotFound,
    Absensi,
    InputAbsensi,
    CutiIndex,
    IzinIndex,
    CutiAjukan,
    IzinAjukan,
    GajiIndex,
    CutiEdit,
    AkunIndex,
    UbahProfile,
    InputAbsensiQR,
    InputAbsensiSelfie,
    UbahKataSandi,
    FAQ,
    Aturan,
    KebijakanPrivasi,
    PerjanjianKerja,
    PengajuanLembur,
    Notifikasi,
    UnduhPerjanjianKerja,
    Lembur,
    Reimburse,
    ReimburseAjukan,
    ReimburseEdit,
} from '../pages';

export const history = createBrowserHistory();

const Routes = () => {
    return (
        <Switch>
            {/* <PrivateRoutes.UserVerifiedCompleted
                exact
                path="/"
                component={Home}
            /> */}

            <PrivateRoutes.UserLogin exact path="/" component={Home} />
            <PrivateRoutes.UserNotLogin path="/login" component={Login} />
            <PrivateRoutes.UserLogin path="/Absensi" component={Absensi} />
            <PrivateRoutes.UserLogin path="/lembur" component={Lembur} />
            <PrivateRoutes.UserLogin path="/reimburse" component={Reimburse} />
            <PrivateRoutes.UserLogin  path="/ajukan/reimburse" component={ReimburseAjukan} />
 <PrivateRoutes.UserLogin
                path="/edit/reimburse/:id"
                component={ReimburseEdit}
            />
            <PrivateRoutes.UserLogin
                path="/notifikasi"
                component={Notifikasi}
            />
            <PrivateRoutes.UserLogin
                path="/InputAbsensi/:id/:code?"
                component={InputAbsensi}
            />
            <PrivateRoutes.UserLogin
                path="/pengajuan_lembur"
                component={PengajuanLembur}
            />
            <PrivateRoutes.UserLogin
                path="/InputAbsensi_qr/:id/:code?"
                component={InputAbsensiQR}
            />
            <PrivateRoutes.UserLogin
                path="/InputAbsensi_selfie/:id/:code?"
                component={InputAbsensiSelfie}
            />
            <PrivateRoutes.UserLogin
                exact
                path="/forgot_password"
                component={Forgot}
            />
            <PrivateRoutes.UserLogin exact path="/izin" component={IzinIndex} />
            <PrivateRoutes.UserLogin
                exact
                path="/izin/ajukan"
                component={IzinAjukan}
            />
            <PrivateRoutes.UserLogin exact path="/cuti" component={CutiIndex} />
            <PrivateRoutes.UserLogin
                exact
                path="/cuti/ajukan"
                component={CutiAjukan}
            />
            <PrivateRoutes.UserLogin
                exact
                path="/cuti/edit/:id"
                component={CutiEdit}
            />
            <PrivateRoutes.UserLogin exact path="/gaji" component={GajiIndex} />
            <PrivateRoutes.UserLogin
                exact
                path="/pdf_link_gaji/"
                component={UnduhSlip}
            />
            <PrivateRoutes.UserLogin exact path="/akun" component={AkunIndex} />
            <PrivateRoutes.UserLogin
                exact
                path="/akun/ubahProfile"
                component={UbahProfile}
            />
            <PrivateRoutes.UserLogin
                exact
                path="/akun/ubahKataSandi"
                component={UbahKataSandi}
            />
            <PrivateRoutes.UserLogin exact path="/akun/faq" component={FAQ} />
            <PrivateRoutes.UserLogin
                exact
                path="/akun/aturan"
                component={Aturan}
            />
            <PrivateRoutes.UserLogin
                exact
                path="/akun/kebijakanPrivasi"
                component={KebijakanPrivasi}
            />
            <PrivateRoutes.UserLogin
                exact
                path="/akun/perjanjianKerja"
                component={PerjanjianKerja}
            />
            <PrivateRoutes.UserLogin
                exact
                path="/pdf_link_perjanjian_kerja"
                component={UnduhPerjanjianKerja}
            />
            <Route component={NotFound} />
        </Switch>
    );
};

export default Routes;
