import { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { DateRangePicker } from 'rsuite';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { API, setAuthToken } from '../../../../config/Api';
import { getListJenisIzin } from '../../../../store/actions/izinCutiAction';

import Title from '../../../../components/Title/Title';
import '../../../../assets/style/mobile-size.css';
import Select from '../../../../components/Input/Select';
import InputFileImg from '../../../../components/Input/InputFileImg';

const IzinAjukan = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const buttonRef = useRef();
    const { beforeToday } = DateRangePicker;

    const { list_jenis_izin } = useSelector((state) => state.izin);

    const [state, setState] = useState({
        bukti_img: '',
        file_preview: '',
        jenis_izin: '',
        list_jenis_izin: [],
        disabled: false
    });

    useLayoutEffect(() => {
        dispatch(getListJenisIzin());
    }, []);

    useEffect(() => {
        if (list_jenis_izin !== []) {
            const data = list_jenis_izin.map((item) => ({
                value: item.jenis_izin_code,
                text: item.jenis_izin
            }));

            setState((prev) => ({
                ...prev,
                list_jenis_izin: data
            }));
        }
    }, [list_jenis_izin]);

    const [dateValue, setDateValue] = useState(null);

    const handleChangeImg = (file, path) => {
        setState({
            ...state,
            bukti_img: file,
            file_preview: path
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        buttonRef.current.disabled = true;
        const data = new FormData(e.currentTarget);

        let object = { foto: '' };

        if (dateValue !== null) {
            object = {
                tanggal_mulai: new Date(dateValue[0])
                    .toISOString()
                    .split('T')[0],
                tanggal_akhir: new Date(dateValue[1])
                    .toISOString()
                    .split('T')[0]
            };

            for (let [key, value] of data.entries()) {
                object = {
                    ...object,
                    [key]: value
                };
            }
        } else {
            buttonRef.current.disabled = false;
            Swal.fire({
                width: '75%',
                title: 'Gagal!',
                text: 'Data input tidak lengkap',
                icon: 'error'
            });
        }

        delete object.foto;

        object = {
            ...object,
            bukti: state.bukti_img
        };

        try {
            const token = localStorage.getItem('token');
            setAuthToken(token);
            API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
            API.post('/user/izin/user/add', object).then((res) => {
                const data = res.data.data;

                if (res.data.code === 2) {
                    buttonRef.current.disabled = false;
                    Swal.fire({
                        width: '75%',
                        title: 'Error!',
                        text: data.msg,
                        icon: 'error'
                    });
                } else {
                    let timerInterval;
                    Swal.fire({
                        title: 'Sedang diproses...',
                        html: 'tunggu dalam waktu <b></b>.',
                        timer: 4000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading();
                            const b =
                                Swal.getHtmlContainer().querySelector('b');
                            setTimeout(() => {
                                clearInterval(timerInterval);
                                history.push('/izin');
                                Swal.fire({
                                    width: '75%',
                                    title: 'Success!',
                                    text: 'Izin berhasil diajukan',
                                    icon: 'success'
                                });
                            }, 4000);
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft();
                            }, 1000);
                        }
                    });
                }
            });
        } catch (e) {
            buttonRef.current.disabled = false;
            Swal.fire('Oopss!', 'Izin gagal diajukan', 'error');
        }
    };

    const title = (
        <div
            onClick={() => history.push('/izin')}
            className="d-flex align-items-center"
        >
            <i className="ri-arrow-left-line"></i>
            <p className="mx-2 my-auto">Ajukan Izin</p>
        </div>
    );
    return (
        <div className="i-ultra-container">
            <form onSubmit={handleSubmit}>
                <Title title={title} />
                <div className="bg-white mb-5 px-4 mt-88">
                    <div className="mt-3">
                        <p className="fs-12 color-semiblack mb-2">Jenis Izin</p>
                        <Select
                            dataSelect={state.list_jenis_izin}
                            state={state}
                            setState={setState}
                            label="Pilih Jenis Izin"
                            name="jenis_izin"
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">
                        <p className="fs-12 color-semiblack mb-2">
                            Tanggal Izin
                        </p>

                        <DateRangePicker
                            block
                            character="-"
                            format="dd MMMM yyyy"
                            size="lg"
                            placeholder="Pilih Tanggal"
                            showOneCalendar
                            value={dateValue}
                            onChange={setDateValue}
                            shouldDisableDate={beforeToday(new Date())}
                        />
                    </div>
                    <div className="mt-4">
                        <p className="fs-12 color-semiblack mb-2">Alasan</p>
                        <textarea
                            name="keterangan"
                            rows="5"
                            cols="100"
                            className="form-control w-full py-14 px-16 fs-12"
                            placeholder="Masukkan alasan kenapa anda izin"
                        />
                    </div>
                    <div className="mt-4 mb-70">
                        <p className="fs-12 color-semiblack mb-2">Bukti</p>

                        <InputFileImg
                            name="bukti_img"
                            uploadFoto={handleChangeImg}
                            preview={state.file_preview}
                            acceptFile="image"
                            noPadding={true}
                        />
                    </div>
                </div>
                <div className="my-2 fixed-bottom px-4">
                    <button
                        ref={buttonRef}
                        type="submit"
                        className="btn w-100 mx-auto text-white izin-ajukan-btn"
                        style={{
                            background: '#00acee'
                        }}
                    >
                        Ajukan Izin
                    </button>
                </div>
            </form>
        </div>
    );
};

export default IzinAjukan;
