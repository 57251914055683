import { GET_LIST_GAJI } from '../constant';

const initialState = {
    data_list_gaji: {}
};

const gajiReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_GAJI:
            return {
                ...state,
                data_list_gaji: action.payload
            };
        default:
            return state;
    }
};

export default gajiReducer;
