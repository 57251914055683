import {
    GET_IZIN_USER,
    GET_LIST_JENIS_IZIN,
    GET_CUTI_USER,
    GET_CUTI_QUANTITY,
    GET_DETAIL_CUTI
} from '../constant';

import { API, setAuthToken } from '../../config/Api';

export const getIzinUser = () => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        const res = await API.get('user/izin/user').catch((err) =>
            Promise.reject(err)
        );

        if (res.data.code === 0) {
            dispatch({
                type: GET_IZIN_USER,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: GET_IZIN_USER,
                payload: []
            });
        }
    };
};

export const getListJenisIzin = () => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        const res = await API.get('user/lainnya/jenis_izin').catch((err) =>
            Promise.reject(err)
        );

        if (res.data.code === 0) {
            dispatch({
                type: GET_LIST_JENIS_IZIN,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: GET_LIST_JENIS_IZIN,
                payload: []
            });
        }
    };
};

export const addIzinUser = async (params) => {
    const token = await localStorage.getItem('token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    return await API.post('/user/izin/user/add', params).catch((err) => {
        return Promise.reject(err);
    });
};

export const getCutiUser = () => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        const res = await API.get('user/cuti/user').catch((err) =>
            Promise.reject(err)
        );

        if (res.data.code === 0) {
            dispatch({
                type: GET_CUTI_USER,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: GET_CUTI_USER,
                payload: []
            });
        }
    };
};

export const getCutiUserList = async () => {
    const token = await localStorage.getItem('token');
    setAuthToken(token);
    return await API.get('/user/cuti/user').catch((err) => Promise.reject(err));
};

export const getCutiQuantity = () => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        const res = await API.get('user/cuti/user/rekap').catch((err) =>
            Promise.reject(err)
        );

        if (res.data.code === 0) {
            dispatch({
                type: GET_CUTI_QUANTITY,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: GET_CUTI_QUANTITY,
                payload: []
            });
        }
    };
};

export const getDetailCuti = (id) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        const res = await API.get('user/cuti/user/' + id).catch((err) =>
            Promise.reject(err)
        );

        if (res.data.code === 0) {
            dispatch({
                type: GET_DETAIL_CUTI,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: GET_DETAIL_CUTI,
                payload: []
            });
        }
    };
};

export const addCutiUser = (params) => {
    const token = localStorage.getItem('token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    return API.post('/user/cuti/user/add', params).catch((err) =>
        Promise.reject(err)
    );
};

export const editCutiUser = (params) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
        const res = await API.post('/user/cuti/user/update', params).catch(
            (err) => {
                return Promise.reject(err);
            }
        );
        return res.data;
    };
};
