import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { getUnitKerja } from '../../store/actions/unitKerjaAction';

function SelectText({
    dataSelect = [],
    label = '',
    getUnitKerja,
    dataUnitKerja,
    unitKerja,
    search,
    value,
    handleChange
}) {
    const getData = () => {
        getUnitKerja();
    };

    useEffect(() => {
        getData();
    }, []);

    const data = dataUnitKerja;

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            '& .MuiOutlinedInput-root': {
                backgroundColor: search ? '#ffffff' : '#fbfbfb ',
                height: search ? '38px' : '46px'
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: search ? null : '#e6e9ed',
                borderWidth: search ? null : '1px'
            },
            '& .MuiSelect-select': {
                fontWeight: '400',
                fontSize: '14px',
                color: '#0A0A0A'
            },
            '& .MuiInputLabel-outlined': {
                color: '#9196ab',
                fontSize: search ? null : '14px',
                top: search ? '-7px' : '18px',
                left: search ? null : '14px',
                transform: search ? null : 'translate(0, 0) scale(1)',
                '&[data-shrink="true"]': {
                    top: search ? 'null' : '-6px',
                    fontSize: search ? null : '12px',
                    fontWeight: search ? null : '400',
                    color: '#6B7280',
                    transform: search ? null : 'translate(0, 0) scale(1)'
                }
            }
        }
    }));
    const classes = useStyles();

    const menuList = data?.map((data, index) => {
        const valueList = data.unit_kerja_code;
        const name = data.unit_kerja;
        return (
            <MenuItem key={index} text={name} value={valueList}>
                {name}
            </MenuItem>
        );
    });

    return (
        <div className="w-full">
            <FormControl
                variant="outlined"
                className={classes.root}
                InputLabelProps={{ classes: { root: classes.inputLabel } }}
            >
                {/* <InputLabel shrink={false}>{value}</InputLabel> */}
                
                <Select
                    value={value}
                    onChange={handleChange}
                    className="w-full"
                    IconComponent={KeyboardArrowDownIcon}
                    variant="outlined"
                >
                    {menuList}
                </Select>
            </FormControl>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        dataUnitKerja: state.lainnya.dataUnitKerja
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUnitKerja: () => dispatch(getUnitKerja())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectText);
