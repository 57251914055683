import { DETAIL_USER, DETAIL_USER_CODE, DETAIL_USERNAME, GET_NOTIF, READ_NOTIF } from '../constant';

const initialState = {
    user: null,
    user_detail_code: null,
    notif: null
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${DETAIL_USER}_PENDING`:
            return {
                ...state,
                user: null
            };
        case `${DETAIL_USER}_FULFILLED`:
            return {
                ...state,
                user: action.payload.user
            };
        case `${DETAIL_USER}_REJECTED`:
            return {
                ...state,
                user: null
            };

        case `${DETAIL_USER_CODE}_PENDING`:
            return {
                ...state,
                user_detail_code: null
            };
        case `${DETAIL_USER_CODE}_FULFILLED`:
            return {
                ...state,
                user_detail_code: action.payload
            };
        case `${DETAIL_USER_CODE}_REJECTED`:
            return {
                ...state,
                user_detail_code: null
            };
        case `${DETAIL_USERNAME}_PENDING`:
            return {
                ...state,
                user_detail_code: null
            };
        case `${DETAIL_USERNAME}_FULFILLED`:
            return {
                ...state,
                user_detail_code: action.payload
            };
        case `${DETAIL_USERNAME}_REJECTED`:
            return {
                ...state,
                user_detail_code: null
            };

        case `${GET_NOTIF}_PENDING`:
            return {
                ...state,
                notif: null
            };
        case `${GET_NOTIF}_FULFILLED`:
            return {
                ...state,
                notif: action.payload
            };
        case `${GET_NOTIF}_REJECTED`:
            return {
                ...state,
                notif: null
            };

        case `${READ_NOTIF}_PENDING`:
            return {
                ...state
            };
        case `${READ_NOTIF}_FULFILLED`:
            return {
                ...state,
                notif: {
                    ...state.notif,
                    isRead: true
                }
            };
        case `${READ_NOTIF}_REJECTED`:
            return {
                ...state
            };

        default:
            return state;
    }
};

export default userReducer;
