import { GET_DATA_UNIT_KERJA } from '../actions/unitKerjaAction';

const initialState = {
    lainnya: {
        dataUnitKerja: [],
    }
};

const LainnyaReducer = (state = initialState.lainnya, action) => {
    switch (action.type) {
        case GET_DATA_UNIT_KERJA: {
            return {
                ...state,
                dataUnitKerja: action.payload
            };
        }
            
        default: {
            return state;
        }
    }
};

export default LainnyaReducer;
