import { API, setAuthToken } from '../../config/Api';
import { GET_ATURAN_KEBIJAKAN, GET_FAQ } from '../constant';

export const getDetailKaryawan = () => {
    const token = localStorage.getItem('token');
    setAuthToken(token);
    const id = localStorage.getItem('users_code');
    return API.get('/user/karyawan/detail/' + id);
};

export function updateProfileUserMobile(params) {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
        const res = await API.post('user/profile/user/edit', params).catch(
            (err) => {
                return Promise.reject(err);
            }
        );

        return res.data;
    };
}

export function updateKataSandiMobile(params) {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
        return API.post('user/profile/user/ubahKataSandi', params)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
}

export function logout(params) {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        localStorage.clear();
        return API.post('user/logout', params)
            .then((res) => {
                localStorage.clear();
                return res.data;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
}

export const getFaq = () => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);

        const res = await API.get('/user/akun/faq').catch((err) =>
            Promise.reject(err)
        );

        if (res.data.code === 0) {
            dispatch({
                type: GET_FAQ,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: GET_FAQ,
                payload: []
            });
        }
    };
};

export const getAturanKebijakan = () => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);

        const res = await API.get('/user/akun/aturan_kebijakan').catch((err) =>
            Promise.reject(err)
        );

        if (res.data.code === 0) {
            dispatch({
                type: GET_ATURAN_KEBIJAKAN,
                aturan: res.data.data.aturan,
                kebijakan: res.data.data.kebijakan
            });
        } else {
            dispatch({
                type: GET_ATURAN_KEBIJAKAN,
                payload: []
            });
        }
    };
};
