import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IcClock, IcBell, IcGpsPoint } from '../../assets/icons';
import '../../assets/style/homepage.css';
import bg_pattern from '../../assets/images/bg_pattern.png';
import { getRiwayatAbsensiKaryawan } from '../../store/actions/absenAction';
import { connect } from 'react-redux';
import { logout } from '../../store/actions/authAction';
import { Sidebar, CardMessages } from '../../components';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import {
    getNotifikasi,
    getSuratPeringatan
} from '../../store/actions/notifikasiAction';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import { getWaktuAbsensi } from '../../store/actions/absenAction';
import { changeTimeFormat } from '../../utils/timeFormat';

function locationToString(location) {
    const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${location.latitude}&lon=${location.longitude}`;
    return fetch(url)
        .then((response) => response.json())
        .then((data) => {
            const address = [];
            if (data.address.road) {
                address.push(data.address.road);
            }
            if (data.address.suburb) {
                address.push(data.address.suburb);
            }
            if (data.address.city_district) {
                address.push(data.address.city_district);
            }
            if (data.address.city) {
                address.push(data.address.city);
            }
            if (data.address.country) {
                address.push(data.address.country);
            }
            return address.join(', ');
        });
}

const Home = ({
    getRiwayatAbsensiKaryawan,
    getWaktuAbsensi,
    waktuAbsensi,
    dataAbsen,
    logout,
    dataNotifikasi,
    getNotifikasi,
    getSuratPeringatan,
    dataPeringatan
}) => {
    function countAttendance(data = []) {
        return data?.reduce(
            (counts, day) => {
                const waktuMasuk = day.jam_masuk.trim();
                const status = day.status;
                if (status === 'izin' || status === 'cuti') {
                    counts.izinCuti++;
                } else {
                    const jamMasuk = parseInt(waktuMasuk.split(':')[0]);
                    if (jamMasuk <= 8) {
                        counts.onTime++;
                    } else if (status === 'terlambat') {
                        counts.late++;
                    }
                }

                return counts;
            },
            { onTime: 0, late: 0, izinCuti: 0 }
        );
    }
    const counts = countAttendance(dataAbsen);
    const [show, setShow] = useState(false);
    const [address, setAddress] = useState('');
    const [username, setUsername] = useState(null);
    const [unitKerja, setUnitKerja] = useState(null);
    const [users_code, setUsersCode] = useState(null);
    const [suratPeringatan, setSuratPeringatan] = useState({ sp_ke: 0 });

const days = [
        'Minggu',
        'Senin',
        'Selasa',
        'Rabu',
        'Kamis',
        'Jumat',
        'Sabtu'
    ];
    const months = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember'
    ];

    const notifCount = dataNotifikasi.filter(
        (item) => item.status === 'dikirim'
    ).length;

    const jamMasuk = changeTimeFormat(waktuAbsensi?.data?.jam_akhir_masuk);

    const jamKeluar = changeTimeFormat(waktuAbsensi?.data?.jam_awal_keluar);
    const history = useHistory();


    useEffect(() => {
        let isMounted = true;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    const location = { latitude, longitude };
                    const result = await locationToString(location);
                    if (isMounted) {
                        setAddress(result);
                    }
                },
                (error) => {
                    console.error(error);
                    if (isMounted) {
                        setAddress('Unable to retrieve your location');
                    }
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
            setAddress('Geolocation is not supported by this browser.');
        }
        return () => {
            isMounted = false;
        }; 
    }, []);

 

    useEffect(() => {
        getData();

        async function getUsername() {
            const asyncUsername = await localStorage.getItem('username');
            setUsername(asyncUsername);
        }
        getUsername();
        async function getUnitKerja() {
            const asyncUsername = await localStorage.getItem('unit_kerja');
            setUnitKerja(asyncUsername);
        }
        getUnitKerja();
        async function getUsersCode() {
            const asyncUsername = await localStorage.getItem('users_code');
            setUsersCode(asyncUsername);
        }
        getUsersCode();

        document.title = 'AlanHR | Home';
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;

        getDataPeringatan(users_code);

    }, []);

     
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            const today = new Date();
            if (today.getDate() !== date.getDate()) {
                setDate(today);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);


    useEffect(() => {
                const data = dataAbsen;
                if (data && data.length > 0) {
                       const { jam_pulang, jam_masuk, status } =
            dataAbsen[0];
                    if (status === 'izin' || status === 'cuti') {
                        setShow(true);
                    }

                    if (jam_pulang && jam_masuk) {
            setShow(true);
                }
            }
        }
    , [dataAbsen]);

     useEffect(() => {
        if (dataPeringatan) {
            setSuratPeringatan(dataPeringatan);
        }
    }, [dataPeringatan]);



    const getData = () => {
        let params = ``;

        getRiwayatAbsensiKaryawan(params);
        getNotifikasi();
        getWaktuAbsensi();
        getSuratPeringatan(users_code);
    };

    const getDataPeringatan = () => {
        getSuratPeringatan(users_code);
    };

  

  

    const handleSubmit = () => {
        logout()
            .then((res) => {
                Swal.fire({
                    title: 'Success',
                    text: `logout success   `,
                    icon: 'success',
                    iconColor: '#00ACEE',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    history.push('/login');
                });
            })
            .catch((error) => {
                const err = error.response.data;
                if (err.code === 2) {
                    Swal.fire({
                        title: 'Error',
                        text: err.message,
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false
                    });
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: 'Login failed, try again',
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false
                    });
                }
            });
    };


    const buttonLink = () => {
        if (!dataAbsen || !dataAbsen || dataAbsen.length === 0) {
            return '';
        }

        const { jam_pulang, jam_masuk, absensi_karyawan_code, status } =
            dataAbsen[0];

        if (!jam_pulang && jam_masuk) {
            return {
                pathname: `/InputAbsensi/Keluar/${absensi_karyawan_code}`,
                state: { waktuMasuk: jam_masuk }
            };
        } else if (!jam_masuk) {
            return {
                pathname: '/InputAbsensi/Masuk'
            };
        } else if (jam_pulang && jam_masuk) {
            setShow(true);
            return '/';
        } else if (status === 'izin' || status === 'cuti') {
            setShow(true);
            return '/';
        } else {
            return '';
        }
    };



    const buttonText = () => {
        const data = dataAbsen;
        if (data && data.length > 0) {
            if (!data[0]?.jam_pulang && data[0]?.jam_masuk) {
                return 'Absen Pulang';
            } else if (!data[0]?.jam_masuk) {
                return 'Absen Masuk';
            } else if (data[0]?.jam_pulang && data[0]?.jam_masuk) {
                return 'Absen Masuk';
            }else if   (data[0]?.status === 'izin' && data[0]?.status === 'cuti') {
                setShow(true);
                return 'Absen Masuk';
            }
        }
        return '';
    };

      

    const jamText = () => {
        const data = dataAbsen;
        if (data && data.length > 0) {
            if (!data[0]?.jam_pulang && data[0]?.jam_masuk) {
                return `jam keluar ${jamKeluar} WIB`;
            } else if (!data[0]?.jam_masuk) {
                return `jam masuk ${jamMasuk} WIB`;
            } else if (data[0]?.jam_pulang && data[0]?.jam_masuk) {
                return `jam masuk ${jamMasuk} WIB`;
            }
        }
        return '';
    };

   
   
    return (
        <div className='wrapper'>
            <Sidebar isHome={true}>
                <div className="home-user-info-container">
                    <div className="d-flex justify-content-between">
                        <div className="home-user-info">
                            <h1>{username}</h1>
                            <h2>{unitKerja}</h2>
                        </div>
                        <Link to="/notifikasi" className="notification-icon">
                            <div className="notification-count">
                                {notifCount}
                            </div>
                            <img src={IcBell} alt="bell" />
                        </Link>
                    </div>
                    <div className="home-user-location">
                        <img src={IcGpsPoint} alt="gps" />
                        {address ? <p>{address}</p> : <p>Loading...</p>}
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center container-home  ">
                    <img
                        src={bg_pattern}
                        alt="bg_pattern"
                        className="bg-pattern"
                    />

                    <div className="home-card-wrapper">
                        <div className="absent-info-container">
                            <div className="date-container">
                                {/* <button onClick={() => handleSubmit()}>
                                    LOGOUT
                                </button> */}

                                <h1>
                                    {days[date.getDay()]}, {date.getDate()}{' '}
                                    {months[date.getMonth()]}{' '}
                                    {date.getFullYear()}
                                </h1>
                                <div
                                    className="d-flex flex-row align-items-center justify-contents-between"
                                    style={{
                                        marginTop: '5px'
                                    }}
                                >
                                    <img
                                        src={IcClock}
                                        alt="clock"
                                        style={{
                                            marginRight: '10px'
                                        }}
                                    />
                                    <h2>{jamText()}</h2>
                                </div>
                            </div>
                       {show ? ( <div
                                    className=  "absensi-button-disabled" 
                                >
                                    <Button  disabled={show}>{buttonText()}</Button>
                                </div>): ( <Link
                                    className= "absensi-button"
                                    to={() => buttonLink()}
                                >
                                    <Button  >{buttonText()}</Button>
                                </Link>)}
                               
                        </div>
                        {suratPeringatan?.sp_ke == 1 && (
                            <div className="peringatan-card bg-warning py-1 mt-2">
                                Surat Peringatan I
                            </div>
                        )}
                        {suratPeringatan?.sp_ke == 2 && (
                            <div className="peringatan-card bg-danger py-1 mt-2">
                                Surat Peringatan II
                            </div>
                        )}
                        {suratPeringatan?.sp_ke == 3 && (
                            <div className="peringatan-card bg-secondary py-1 mt-2">
                                Anda Dipecat
                            </div>
                        )}

                        <div className="content  d-flex flex-row  justify-contents-between  flex-1 absent-stats w-90percent mt-3">
                            <div className="flex-1">
                                <h1>{counts?.onTime}</h1>
                                <h2>Tepat Waktu</h2>
                            </div>
                            <div className="w-1px  h-50px  bg-grey-border br-2  "></div>
                            <div className="flex-1">
                                <h1>{counts?.late}</h1>
                                <h2>Telat Hadir</h2>
                            </div>
                            <div className="w-1px  h-50px  bg-grey-border br-2  "></div>
                            <div className="flex-1">
                                <h1>{counts?.izinCuti}</h1>
                                <h2>Izin & Cuti</h2>
                            </div>
                        </div>
                        <div className="card-container-title">
                            <p>Absensi bulan ini</p>
                        </div>
                        <div className="home-card-container">
                            {dataAbsen?.map((item, index) => (
                                <CardMessages
                                    key={index}
                                    isAbsent={true}
                                    waktuMasuk={item.jam_masuk}
                                    waktuKeluar={item.jam_pulang}
                                    tanggal={item?.tanggal}
                                    kehadiran={item?.status}
                                    code={item?.absensi_karyawan_code || ''}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </Sidebar>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        dataAbsen: state.absen.dataAbsen,
        waktuAbsensi: state.absen.waktuAbsensi,
        dataPeringatan: state.notifikasi.dataPeringatan,
        dataNotifikasi: state.notifikasi.dataNotifikasi
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getRiwayatAbsensiKaryawan: (params) =>
            dispatch(getRiwayatAbsensiKaryawan(params)),
        logout: () => dispatch(logout()),
        getNotifikasi: () => dispatch(getNotifikasi()),
        getWaktuAbsensi: () => dispatch(getWaktuAbsensi()),
        getSuratPeringatan: (code) => dispatch(getSuratPeringatan(code))
        // delAturTitikAbsensi: (code) => dispatch(delAturTitikAbsensi(code))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
