import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DateRangePicker } from 'rsuite';
import Swal from 'sweetalert2';

import Title from '../../../../components/Title/Title';
import '../../../../assets/style/mobile-size.css';
import { API, setAuthToken } from '../../../../config/Api';

const CutiAjukan = () => {
    const history = useHistory();
    const { beforeToday } = DateRangePicker;
    const buttonRef = useRef();
    const [dateValue, setDateValue] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        buttonRef.current.disabled = true;
        const data = new FormData(e.currentTarget);

        let object = { foto: '' };

        if (dateValue !== null) {
            object = {
                tanggal_mulai: new Date(dateValue[0])
                    .toISOString()
                    .split('T')[0],
                tanggal_akhir: new Date(dateValue[1])
                    .toISOString()
                    .split('T')[0]
            };

            for (let [key, value] of data.entries()) {
                object = {
                    ...object,
                    [key]: value
                };
            }
        } else {
            buttonRef.current.disabled = false;
            Swal.fire({
                width: '75%',
                title: 'Gagal!',
                text: 'Data input tidak lengkap',
                icon: 'error'
            });
        }

        try {
            const token = localStorage.getItem('token');
            setAuthToken(token);
            API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
            API.post('/user/cuti/user/add', object).then((res) => {
                const data = res.data.data;
                console.log(res);

                if (res.data.code === 2) {
                    buttonRef.current.disabled = false;
                    Swal.fire('Oopss!', data.msg, 'error');
                } else {
                    let timerInterval;
                    Swal.fire({
                        title: 'Sedang diproses...',
                        html: 'tunggu dalam waktu <b></b>.',
                        timer: 4000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading();
                            const b =
                                Swal.getHtmlContainer().querySelector('b');
                            setTimeout(() => {
                                clearInterval(timerInterval);
                                history.push('/cuti');
                                Swal.fire({
                                    width: '75%',
                                    title: 'Success!',
                                    text: 'Cuti berhasil diajukan',
                                    icon: 'success'
                                });
                            }, 4000);
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft();
                            }, 1000);
                        }
                    });
                }
            });
        } catch (e) {
            buttonRef.current.disabled = false;
            Swal.fire('Oopss!', 'Cuti gagal diajukan', 'error');
        }
    };

    const title = (
        <div
            onClick={() => history.push('/cuti')}
            className="d-flex align-items-center"
        >
            <i className="ri-arrow-left-line"></i>
            <p className="mx-2 my-auto">Ajukan Cuti</p>
        </div>
    );

    return (
        <div className="i-ultra-container">
            <form onSubmit={handleSubmit}>
                <Title title={title} />
                <div className="bg-white px-4 mt-80">
                    <div className="mt-4">
                        <p className="fs-12 color-semiblack mb-2">
                            Tanggal Cuti
                        </p>

                        <DateRangePicker
                            block
                            placement="bottomEnd"
                            character="-"
                            format="dd MMMM yyyy"
                            size="lg"
                            placeholder="Pilih Tanggal"
                            showOneCalendar
                            value={dateValue}
                            onChange={setDateValue}
                            shouldDisableDate={beforeToday(new Date())}
                        />
                    </div>
                    <div className="mt-4">
                        <p className="fs-12 color-semiblack mb-2">Alasan</p>
                        <textarea
                            name="keterangan"
                            rows="5"
                            cols="100"
                            className="form-control w-full py-14 px-16 fs-12"
                            placeholder="Masukkan alasan kenapa anda cuti"
                        />
                    </div>
                </div>
                <div className="my-1 fixed-bottom px-4">
                    <button
                        ref={buttonRef}
                        type="submit"
                        className="btn w-100 mx-auto text-white izin-ajukan-btn"
                        style={{
                            background: '#00acee'
                        }}
                    >
                        Ajukan Cuti
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CutiAjukan;
