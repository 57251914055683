import { API, setAuthToken } from '../../config/Api';

export const GET_DATA_UNIT_KERJA = 'GET_DATA_UNIT_KERJA';

export const getUnitKerja = () => {
    return async (dispatch) => {
        // const token = localStorage.getItem('token');
        // setAuthToken(token);
        // console.log('ok');
        // return API.get(`/user/lainnya/unit_kerja`);

        const token = await localStorage.getItem('token');
        setAuthToken(token);
        API.get(`/user/lainnya/unit_kerja`)
            .then((res) => {
                dispatch({
                    type: GET_DATA_UNIT_KERJA,
                    payload: res.data.data || []
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_DATA_UNIT_KERJA,
                    payload: []
                });
            });
    };
};

