import React, { useRef } from 'react';
import { Dialog, Slide } from '@material-ui/core';
import SignaturePad from 'react-signature-canvas';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

import '../../assets/style/perjanjianKerja.css';
import { addTandaTangan } from '../../store/actions/perjanjianKerjaAction';
import { IcClear } from '../../assets/icons';

const ModalTandaTangan = ({ open, handleClose, getData }) => {
    const dispatch = useDispatch();

    let sigPad = useRef({});

    function clear() {
        sigPad.current.clear();
    }

    function save() {
        const data = {
            tanda_tangan: sigPad.current.getTrimmedCanvas().toDataURL()
        };

        dispatch(addTandaTangan(data))
            .then(() => {
                let timerInterval;
                Swal.fire({
                    title: 'Sedang diproses...',
                    html: 'tunggu dalam waktu <b></b>.',
                    timer: 5000,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading();
                        const b = Swal.getHtmlContainer().querySelector('b');
                        setTimeout(() => {
                            clearInterval(timerInterval);

                            Swal.fire(
                                'Success!',
                                'Tanda tangan berhasil disimpan',
                                'success'
                            );

                            handleClose();
                        }, 4000);
                        timerInterval = setInterval(() => {
                            b.textContent = Swal.getTimerLeft();
                        }, 1000);
                    }
                });
                getData();
            })
            .catch((err) => {
                let error = err?.response?.data;
                Swal.fire(
                    Array.isArray(error?.data)
                        ? error?.data[0]
                        : 'Gagal menyimpan data, coba beberapa saat lagi',
                    false
                );
            });
    }

    return (
        <div>
            <Dialog
                maxWidth="md"
                className="mx-auto"
                fullWidth
                open={open}
                onClose={handleClose}
                TransitionComponent={Slide}
                TransitionProps={{
                    direction: 'up',
                    mountOnEnter: true,
                    unmountOnExit: true
                }}
                PaperProps={{
                    style: {
                        position: 'absolute',
                        bottom: '-32px',
                        width: '100vw',
                        height: 'max-content',
                        paddingBlock: '20px',
                        paddingInline: '8px'
                    }
                }}
            >
                <div className="mb-5 overflow-hidden">
                    <div className="border border-primary mb-4 py-2 px-2">
                        <div className="d-flex flex-row-reverse">
                            <img
                                src={IcClear}
                                onClick={clear}
                                width="25px"
                                alt="clear"
                            />
                        </div>
                        <div className="">
                            <SignaturePad
                                ref={sigPad}
                                penColor="black"
                                canvasProps={{
                                    height: 170,
                                    width: 800,
                                    backgroundColor: 'red'
                                }}
                                redrawOnResize
                            />
                        </div>
                    </div>

                    <div className="fixed-bottom mb-4 py-0 d-flex justify-content-between gap-15 px-2 pb-2">
                        <button
                            type="button"
                            className="btn bg-white w-100 py-2 mt-5 border border-secondary text-secondary"
                            onClick={handleClose}
                        >
                            Tutup
                        </button>
                        <button
                            type="button"
                            className="btn bg-white w-100 py-2 mt-5 border border-primary text-primary tombol-selesai"
                            onClick={save}
                        >
                            Selesai
                        </button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default ModalTandaTangan;
