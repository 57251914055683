import { API, setAuthToken } from '../../config/Api';

export const CETAK_KONTRAK_KERJA = 'CETAK_KONTRAK_KERJA';

export const cetakKontrakKerja = () => {
    const token = localStorage.getItem('token');
    setAuthToken(token);
    return API.get('/user/kontrak_kerja/user/cetak');
};

export function ajukanPerjanjianKerja() {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
        return API.post('user/kontrak_kerja/user/ajukan')
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
}

export function addTandaTangan(params) {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
        const res = await API.post(
            'user/kontrak_kerja/user/tanda_tangan/add',
            params
        ).catch((err) => {
            return Promise.reject(err);
        });

        return res.data;
    };
}

export const getTandaTangan = () => {
    const token = localStorage.getItem('token');
    setAuthToken(token);
    return API.get('/user/kontrak_kerja/user/tanda_tangan');
};
