import {
    GET_RIWAYAT_ABSENSI_KARYAWAN,
    GET_LOKASI_ABSENSI,
    GET_WAKTU_ABSENSI
} from '../actions/absenAction';
const initialState = {
    dataAbsen: [],
    lokasiAbsensi: {},
    waktuAbsensi: {}
};

const absenReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_ABSEN_MASUK':
            return {
                ...state,
                absen: [...state.absen, ...action.payload]
            };
        case 'ADD_ABSEN_KELUAR':
            return {
                ...state,
                absen: [...state.absen, ...action.payload]
            };
        case 'CLEAR_ABSEN_STATE':
            return initialState;

        case GET_RIWAYAT_ABSENSI_KARYAWAN: {
            return {
                ...state,
                dataAbsen: action.payload
            };
        }
        case GET_LOKASI_ABSENSI: {
            return {
                ...state,
                lokasiAbsensi: action.payload
            };
        }
        case GET_WAKTU_ABSENSI: {
            return {
                ...state,
                waktuAbsensi: action.payload
            };
        }
        default:
            return state;
    }
};

export default absenReducer;
