import '../../../../assets/style/mobile-size.css';

const CutiQuantity = ({ rekap_cuti }) => {
    const { sisa_cuti, cuti_diproses, cuti_terpakai } = rekap_cuti;
    return (
        <div className="d-flex z-99 i-ultra-container mt-120 py-2 px-2 mx-auto text-center ">
            <div className="rounded-lg px-2 py-4 fs-12 mx-1 flex-fill bg-lightblue">
                <span className="text-blue mr-1 font-weight-bolder">
                    {sisa_cuti}
                </span>
                Sisa Cuti
            </div>
            <div className="rounded-lg px-2 py-4 fs-12 mx-1 flex-fill bg-lightorange">
                <span className="text-orange mr-1 font-weight-bolder">
                    {cuti_diproses}
                </span>
                Cuti Diproses
            </div>
            <div className="rounded-lg px-2 py-4 fs-12 mx-1 flex-fill bg-lightred">
                <span className="text-red mr-1 font-weight-bolder">
                    {cuti_terpakai}
                </span>
                Cuti Terpakai
            </div>
        </div>
    );
};

export default CutiQuantity;
