import { API, setAuthToken } from '../../config/Api';

export const GET_LEMBUR = 'GET_LEMBUR';

export const addLembur = (body) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
        const res = await API.post(`/user/gaji/lembur_auth/add`, body).catch(
            (err) => {
                return Promise.reject(err);
            }
        );

        return res.data;
    };
};

export const editLembur = (body) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
        const res = await API.post(
            `/user/gaji/lembur/update/foto_bukti`,
            body
        ).catch((err) => {
            return Promise.reject(err);
        });

        return res.data;
    };
};

export const getLembur = (params) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        const res = await API.get(`/user/gaji/lembur_auth${params}`).catch(
            (err) => {
                return Promise.reject(err);
            }
        );
        if (res.data.code === 0) {
            dispatch({
                type: GET_LEMBUR,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: GET_LEMBUR,
                payload: []
            });
        }
    };
};
