import React from 'react';
import '../../assets/style/mobile-size.css';

import IzinTabs from '../../components/Tabs/IzinTabs';
import Title from '../../components/Title/Title';

const IzinCutiHeader = () => {
    return (
        <div className="i-ultra-container mx-auto px-0">
            <Title title={'Izin & Cuti'}  subtitle={<IzinTabs />} />
           
        </div>
    );
};

export default IzinCutiHeader;
