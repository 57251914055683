import { useHistory } from 'react-router-dom';

import Title from '../../components/Title/Title';
import '../../assets/style/mobile-size.css';
import '../../assets/style/inputabsensi.css';
import DatePickerComponent from '../../components/Input/DatePickerComponent';

import InputFileImg from '../../components/Input/InputFileImg';
import { TextField, Grid, CircularProgress, Button } from '@material-ui/core';
import { Sidebar } from '../../components';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { addLembur } from '../../store/actions/LemburAction';
import { useDispatch } from 'react-redux';

const PengajuanLembur = () => {
    const currentTime = new Date().toLocaleTimeString('en-US', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit'
    });
    const history = useHistory();
    const [formData, setFormData] = useState({
        tanggal: new Date().toISOString().slice(0, 10),
        keterangan: '',

        waktu_mulai: currentTime,
        waktu_akhir: currentTime,
        foto_bukti: '',
        foto_bukti_preview: ''
    });
    const [submit, setSubmit] = useState(false);
    const dispatch = useDispatch();

    const handleDateChange = (date, name) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: date
        }));
    };

    const handleChange = (id) => (e) => {
        const { value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [id]: value
        }));
    };

    const handleSubmit = async () => {
        await dispatch(
            addLembur({
                keterangan: formData.keterangan,
                waktu_mulai: formData.waktu_mulai,
                waktu_akhir: formData.waktu_akhir,
                // foto_bukti: formData.foto_bukti
                tanggal: formData.tanggal
            })
        )
            .then(() => {
                Swal.fire({
                    title: 'Success',
                    text: `Data Lembur Berhasil Disimpan`,
                    icon: 'success',
                    iconColor: '#00ACEE',
                    timer: 2000,
                    showConfirmButton: false
                });

                history.push('/');
                setSubmit(false);

                setFormData({
                    tanggal: new Date().toISOString().slice(0, 10),
                    keterangan: '',

                    waktu_mulai: new Date(),
                    waktu_akhir: new Date(),
                    foto_bukti: '',
                    foto_bukti_preview: ''
                });
            })
            .catch((err) => {
                let error = err?.response?.data;
                Swal.fire({
                    title: 'Error',
                    text: err.message,
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false
                });
            });
    };

    const handleChangeFoto = (file, path) => {
        setFormData((pref) => ({
            ...pref,
            foto_bukti: file,
            foto_bukti_preview: path
        }));
    };

    return (
        <div className="pengajuan-lembur">
            <Sidebar>
                <Title
                    style={{ height: '10vh' }}
                    title="Pengajuan Lembur"
                    back
                    backLink="/"
                />

                <Grid container spacing={2} className="px-20 mt-15">
                    <Grid item xs={12}>
                        <p>Alasan</p>
                        <div className="">
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={4}
                                placeholder="Keterangan"
                                className="text-field-modifier-multiline"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true
                                }}
                                value={formData?.keterangan}
                                onChange={handleChange('keterangan')}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <p>Jam Masuk</p>
                        <TextField
                            name="jam_masuk"
                            type="time"
                            value={formData.waktu_mulai}
                            onChange={handleChange('waktu_mulai')}
                            variant="standard"
                            className="text-field-modifier-time"
                            size="small"
                            InputProps={{
                                disableUnderline: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <p>Jam Keluar</p>
                        <TextField
                            name="jam_keluar"
                            type="time"
                            value={formData.waktu_akhir}
                            onChange={handleChange('waktu_akhir')}
                            variant="standard"
                            className="text-field-modifier-time"
                            size="small"
                            InputProps={{
                                disableUnderline: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DatePickerComponent
                            state={formData}
                            setState={setFormData}
                            name="tanggal"
                            tipe="dd MMMM yyyy"
                        />
                    </Grid>

                    {/* <Grid item xs={12}>
                        <p>Bukti</p>

                        <div className="">
                            <InputFileImg
                                acceptFile="image"
                                uploadFoto={handleChangeFoto}
                                preview={formData.foto_bukti_preview}
                            />
                        </div>
                    </Grid> */}

                    <Grid item xs={12}>
                        <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            className="mb-4 w-100 py-2 btn-auth text-white"
                            disabled={submit}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            {submit ? (
                                <CircularProgress
                                    size={24}
                                    className="text-salmon"
                                />
                            ) : (
                                `Submit`
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </Sidebar>
        </div>
    );
};

export default PengajuanLembur;
