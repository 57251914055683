export function changeTimeFormat(timeString) {
    if (!timeString) {
        return 'Invalid time';
    }

    const [hours, minutes, seconds] = timeString.split(':');

    if (!hours || !minutes) {
        return 'Invalid time';
    }

    return `${hours}:${minutes}`;
}
