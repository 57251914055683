import { useLayoutEffect, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker } from 'rsuite';
import Swal from 'sweetalert2';

import { getReimburse, getDetailReimburse, delReimburse,getListReimburse } from '../../store/actions/reimburseAction';
import IzinTabs from '../../components/Tabs/IzinTabs';
import MobileNavigation from '../../components/navigation/MobileNavigation';
import '../../assets/style/mobile-size.css';
import Title from '../../components/Title/Title';
import Select from '../../components/Input/Select';
import CardGaji from '../../components/Card/CardGaji';

const Reimburse = () => {
    const useStyles = makeStyles({
        dialog: {
            width: '98%',
            height: 'fit-content',
            scrollbarColor: 'transparent',
            scrollbarWidth: '0px',
            overflow: 'hidden'
        },
        backDrop: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
        }
    });
        const { listReimburse } = useSelector((state) => state.reimburse);

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const name = localStorage.getItem('username');

    const handleTgl = (tgl) => {
        var arr = tgl.split('-');

        if (arr[1] === '01') {
            arr[1] = 'Januari';
        } else if (arr[1] === '02') {
            arr[1] = 'Februari';
        } else if (arr[1] === '03') {
            arr[1] = 'Maret';
        } else if (arr[1] === '04') {
            arr[1] = 'April';
        } else if (arr[1] === '05') {
            arr[1] = 'Mei';
        } else if (arr[1] === '06') {
            arr[1] = 'Juni';
        } else if (arr[1] === '07') {
            arr[1] = 'Juli';
        } else if (arr[1] === '08') {
            arr[1] = 'Agustus';
        } else if (arr[1] === '09') {
            arr[1] = 'September';
        } else if (arr[1] === '10') {
            arr[1] = 'Oktober';
        } else if (arr[1] === '11') {
            arr[1] = 'November';
        } else if (arr[1] === '12') {
            arr[1] = 'Desember';
        }

        const data = arr.reverse().join(' ');

        return data;
    };

    const [state, setState] = useState({
        status: '',
        tahun: new Date(),
        unit_kerja: '',
        id: '',
        name: '',
        tanggal_gajian: '',
        total_gaji: '',
        gaji_pokok: '',
        tambahan: '',
        pengurangan: '',
        open: false,
        status_modal: '',
        pdf_path: '',
        jenis: '',
        keterangan: '',
        disabled: false
    });

    let currentStatus = {};

    if (state.status_modal === 'Diproses') {
        currentStatus = {
            bgStatus: 'bg-warning',
            txtStatus: 'Diproses'
        };
    } else if (state.status_modal === 'Diterima') {
        currentStatus = {
            bgStatus: 'bg-success',
            txtStatus: 'Diterima'
        };
    }else if (state.status_modal === 'Ditolak') {
        currentStatus = {
            bgStatus: 'bg-danger',
            txtStatus: 'Ditolak'
        };
    }


    const { dataReimburse } = useSelector((state) => state.reimburse);

    useLayoutEffect(() => {
        var params = `?status=${
            state.status
        }&jenis=${state.jenis}`;
        dispatch(getReimburse(params));
    }, [state.status, state.tahun]);

    useLayoutEffect(() => {}, [state.id]);

    useEffect(() => {
        if (dataReimburse?.hasOwnProperty('unit_kerja')) {
            setState((prev) => ({
                ...prev,
                unit_kerja: dataReimburse.unit_kerja
            }));
        }
    }, [dataReimburse]);

    const changeDate = (e) => {
        const event = e.target.value;
        setState((prev) => ({
            ...prev,
            tahun: event
        }));
    };


   const handleDelete = (id) => {
    var params = `?status=${
            state.status
        }&tahun=${state.tahun}`;

  Swal.fire({
    title: "Hapus",
    text: "Apakah kamu yakin ?",
    showCancelButton: true,
    confirmButtonText: "Yakin",
    cancelButtonText: "Batal",
    icon: "warning",
  }).then((res) => {
    if (res.isConfirmed) {
      dispatch(delReimburse(id))
        .then((res) => {
          if (res.code === 0) {
            Swal.fire({
              title: "Berhasil",
              text: "Data berhasil dihapus",
              timer: 2000,
              icon: "success",
            });
        dispatch(getReimburse(params));
          }
        })
        .catch((err) => {
          Swal.fire({
            title: "gagal",
            text: "Data Gagal dihapus",
            timer: 2000,
            icon: "error",
          });
        });
    }
  });
};

 useLayoutEffect(() => {
         let params = ``;
        dispatch(getListReimburse(params));
    }, []);

 useEffect(() => {
        if (listReimburse !== []) {
            const data = listReimburse.map((item) => ({
                value: item.jenis_reimburse_code,
                text: item.jenis_reimburse
            }));

            setState((prev) => ({
                ...prev,
                listReimburse: data
            }));
        }
    }, [listReimburse]);



    return (
        <div className="i-ultra-container mx-auto">
                <Title title={'Reimburse'} subtitle={<IzinTabs  />} />
                <Grid
                    container
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                    className=" px-3 py-2 "
                    style={{ marginTop: '110px' }}
                >
                    <Grid item xs={6}>
                        <Select
                            dataSelect={[
                               
                                {
                                    text: 'Diproses',
                                    value: 'Diproses'
                                }, {
                                    text: 'Diterima',
                                    value: 'Diterima'
                                },
                                {
                                    text: 'Ditolak',
                                    value: 'Ditolak'
                                },
                                {
                                    text: 'Lihat Semua',
                                    value: ''
                                }
                            ]}
                            state={state}
                            setState={setState}
                            label="Status"
                            name="status"
                            width="100%"
                        />
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                            dataSelect={state.listReimburse}
                            state={state}
                            setState={setState}
                            label="Jenis"
                            name="jenis"
                            width="100%"
                        />
                    </Grid>
                </Grid>
            <div className="position-relative mt-10  mb-100">
                {
                dataReimburse?.length !== 0 ? (
                    dataReimburse?.map((data) => (
                        <div key={data?.reimburse_code
} className="p-0 m-0">
                            <CardGaji
                                id={data?.reimburse_code
}
                                name={name}
                                tanggal_gajian={data?.tanggal}
                                unit_kerja={data?.unit_kerja}
                                total_gaji={data?.nominal}
                                status={data?.status}
                                setState={setState}
                                handleTgl={handleTgl}
                                jenis={data?.jenis_reimburse}
                                keterangan={data?.keterangan}
                                reimburse
                            />
                        </div>
                    ))
                ) : (
                    <div className="mx-auto my-8 text-center">
                        data reimburse  kosong
                    </div>
                )}
            </div>
            <Dialog
                open={state.open}
                onClose={() => setState((prev) => ({ ...prev, open: false }))}
                aria-labelledby="responsive-dialog-title"
                classes={{
                    paper: classes.dialog
                }}
                BackdropProps={{
                    classes: {
                        root: classes.backDrop
                    }
                }}
            >
                <Grid
                    container
                    spacing={3}
                    justifyContent="space-between"
                    className="px-3 my-2"
                >
                    <Grid
                        item
                        xs={12}
                        className="fs-16 font-weight-bold text-black mb-1"
                    >
                        Detail Reimbursement
                    </Grid>
                    <Grid item xs={6}>
                        <p className="font-weight-medium fs-11 text-abu-muda">
                            Nama Pegawai
                        </p>
                        <p className="font-weight-medium fs-13">{state.name}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <p className="font-weight-medium fs-11 text-abu-muda">
                            Jenis Reimburse
                        </p>
                        <p className="font-weight-medium fs-13">{state.jenis}</p>
                    </Grid>
                    
                    <Grid item xs={6}>
                        <p className="font-weight-medium fs-11 text-abu-muda">
                            Unit Kerja
                        </p>
                        <p className="font-weight-medium fs-13">
                            {state.unit_kerja}
                        </p>
                    </Grid>
                    <Grid item xs={6}>
                        <p className="font-weight-medium fs-11 text-abu-muda">
                            Status
                        </p>
                        <div
                            className={`${currentStatus.bgStatus} rounded-lg d-flex justify-content-center w-max-content mt-1`}
                        >
                            <p className="mx-3 fs-12 my-2 text-white">
                                {currentStatus.txtStatus}
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <p className="font-weight-medium fs-11 text-abu-muda">
                            Dibuat Tanggal
                        </p>
                        <p className="font-weight-medium fs-13">
                            {handleTgl(state.tanggal_gajian)}
                        </p>
                    </Grid>
                    <Grid item xs={6}>
                        <p className="font-weight-medium fs-11 text-abu-muda">
                            Nominal
                        </p>
                        <p className="font-weight-medium fs-13">
                            Rp.{' '}
                            {state.total_gaji
                                .toString()
                                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')}
                        </p>
                    </Grid>
                     <Grid item xs={12}>
                        <p className="font-weight-medium fs-11 text-abu-muda">
                            Keterangan
                        </p>
                        <p className="font-weight-medium fs-13">{state.keterangan}</p>
                    </Grid>
                    <Grid item xs={12}>
                        <p className="font-weight-medium fs-11 text-abu-muda">
                            Bukti
                        </p>
                        <img src={state.bukti} alt="bukti" />
                    </Grid>
                    
                    {state.status_modal.toLowerCase() === 'diproses'  ? (
                        <Grid
                            item
                            xs={12}
                            className="d-flex justify-content-between"
                        >
                            <button
                                className="w-100 rounded-6 border-bottom-red text-red py-8px bg-lightred mr-2"
                               onClick={() => {
  handleDelete(state.id);
  setState((prev) => ({
    ...prev,
    open: false
  }));
}}

                            >
                                Batalkan
                            </button>

                            <button
                                className={`w-100 rounded-6 py-8px ml-2 ${
                                    state.disabled
                                        ? 'border-bottom-grey text-semiblack bg-light-grey'
                                        : 'border-bottom-blue text-blue bg-lightblue'
                                }`}
                               
                            >
                                <Link
                                    to={`/edit/reimburse/${state.id}`}
                                    >                                Edit
</Link>
                            </button>
                        </Grid>
                    ) : (
                        <Grid
                            item
                            xs={12}
                            className="d-flex justify-content-between"
                        >
                            <button
                                className="w-100 rounded-6 text-abu-tua padblock-8 border-1-grey"
                                onClick={() =>
                                    setState((prev) => ({
                                        ...prev,
                                        open: false
                                    }))
                                }
                            >
                                Tutup
                            </button>
                        </Grid>
                    )}
                </Grid>
            </Dialog>
                            <Link to="/ajukan/reimburse" className="text-white">

            <button
                // onClick={() => history.push('reimburse/ajukan')}
                type="button"
                className="mx-auto btn btn-lg fixed-bottom fs-11 i-btn-bottom i-ultra-container"
            >
                                    Ajukan Reimburse

            </button>
                                </Link>

            <div>
                <MobileNavigation />
            </div>
        </div>
    );
};

export default Reimburse;
