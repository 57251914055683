import { API, setAuthToken } from '../../config/Api';

export const GET_REIMBURSE = 'GET_REIMBURSE';
export const GET_DETAIL_REIMBURSE = 'GET_DETAIL_REIMBURSE';
export const DEL_REIMBURSE = 'DEL_REIMBURSE';
export const GET_LIST_REIMBURSE = 'GET_LIST_REIMBURSE';


export const addReimburse = (body) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
        const res = await API.post(`/user/gaji/mobile/reimburse`, body).catch(
            (err) => {
                return Promise.reject(err);
            }
        );

        return res.data;
    };
};


export const editReimburse = (body) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
        const res = await API.patch(
            `/user/gaji/mobile/reimburse`,
            body
        ).catch((err) => {
            return Promise.reject(err);
        });

        return res.data;
    };
};

export const getReimburse = (params) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        const res = await API.get(`/user/gaji/mobile/reimburse${params}`).catch(
            (err) => {
                return Promise.reject(err);
            }
        );
        if (res.data.code === 0) {
            dispatch({
                type: GET_REIMBURSE,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: GET_REIMBURSE,
                payload: []
            });
        }
    };
};

export const getListReimburse = (params) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        const res = await API.get(`/user/lainnya/jenis_reimburse${params}`).catch(
            (err) => {
                return Promise.reject(err);
            }
        );
        if (res.data.code === 0) {
            dispatch({
                type: GET_LIST_REIMBURSE,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: GET_LIST_REIMBURSE,
                payload: []
            });
        }
    };
};




export const getDetailReimburse = (code) => {
    return async (dispatch) => {
        const token = await localStorage.getItem('token');
        setAuthToken(token);
        const res = await API.get(`/user/gaji/mobile/reimburse/detail/${code}`).catch(
            (err) => {
                return Promise.reject(err);
            }
        );
        if (res.data.code === 0) {
            dispatch({
                type: GET_DETAIL_REIMBURSE,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: GET_DETAIL_REIMBURSE,
                payload: []
            });
        }
    };
};


export const delReimburse = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(`user/gaji/mobile/reimburse/delete/${code}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: DEL_REIMBURSE,
        payload: code,
      });
    }
    return res.data;
  };
};