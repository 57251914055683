import {
    GET_IZIN_USER,
    GET_LIST_JENIS_IZIN,
    GET_CUTI_USER,
    GET_CUTI_QUANTITY,
    GET_DETAIL_CUTI
} from '../constant';

const initialState = {
    izin: false,
    data_izin: [],
    data_cuti: {
        data: [],
        unit_kerja: ''
    },
    list_jenis_izin: [],
    rekap_cuti: {},
    detail_cuti: []
};

const izinCutiReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_IZIN_USER:
            return {
                ...state,
                data_izin: action.payload
            };
        case GET_LIST_JENIS_IZIN:
            return {
                ...state,
                list_jenis_izin: action.payload
            };
        case GET_CUTI_USER:
            return {
                ...state,
                data_cuti: action.payload
            };
        case GET_CUTI_QUANTITY:
            return {
                ...state,
                rekap_cuti: action.payload
            };
        case GET_DETAIL_CUTI:
            return {
                ...state,
                detail_cuti: action.payload
            };
        default:
            return state;
    }
};

export default izinCutiReducer;

/*
"id": 15,
"jenis_izin": "JENISIZIN-302023031504039399189",
"status": "diproses",
"tanggal_mulai": "2023-04-01",
"tanggal_akhir": "2023-04-02",
"keterangan": "izin turu"
*/
