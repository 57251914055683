import { GET_REIMBURSE,GET_DETAIL_REIMBURSE,DEL_REIMBURSE,GET_LIST_REIMBURSE } from '../actions/reimburseAction';
const initialState = {
    dataReimburse: [],
    listReimburse: [],
    detailReimburse: {}
};

const reimburseReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case GET_REIMBURSE: {
            return {
                ...state,
                dataReimburse: action.payload
            };
        }
        case GET_LIST_REIMBURSE: {
            return {
                ...state,
                listReimburse: action.payload
            };
        }
        case GET_DETAIL_REIMBURSE: {
            return {
                ...state,
                detailReimburse: action.payload
            };
        }
         case DEL_REIMBURSE: {
      let penilaian = state.data;
      return {
        ...state,
        dataReimburse: penilaian,
      };
    }

        default:
            return state;
    }
};

export default reimburseReducer;
