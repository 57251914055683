import { useHistory } from 'react-router-dom';
import Title from '../../components/Title/Title';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAturanKebijakan } from '../../store/actions/akunAction';

import '../../assets/style/ubahProfile.css';

const KebijakanPrivasi = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { kebijakan } = useSelector((state) => state.akun);

    useEffect(() => {
        dispatch(getAturanKebijakan());
    }, []);

    const title = (
        <div onClick={() => history.push('/akun')} className="d-flex">
            <i className="ri-arrow-left-line"></i>
            <p className="mx-2">Kebijakan Privasi</p>
        </div>
    );
    return (
        <div className="i-ultra-container mx-auto p-0">
            <Title title={title} />
            <div class="w-100 mt-75 mb-20 px-4">
                {kebijakan === [] ? (
                    <div className="mx-auto my-8 text-center">
                        data Kebijakan Privasi kosong
                    </div>
                ) : (
                    <div
                        className="break-word"
                        dangerouslySetInnerHTML={{
                            __html: kebijakan.content
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default KebijakanPrivasi;
