import { ThemeProvider } from '@material-ui/styles';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import history from './history';
import { store } from './store';
import Routes from './routes';
import theme from './theme';

const App = () => {
    return (
         <div className='wrapper'>
        <Provider store={store}>
            {/* <PersistGate loading={null} persistor={persist}> */}
            <ThemeProvider theme={theme}>
                <Router>
                    <Route history={history}>
                        <Routes />
                    </Route>
                </Router>
            </ThemeProvider>
            {/* </PersistGate> */}
        </Provider>
        </div>
    );
};

export default App;
