import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addAbsenMasuk, addAbsenKeluar } from '../../store/actions/absenAction';
import { Sidebar, CardMessages, Title, ModalPreview } from '../../components';
import '../../assets/style/absensipage.css';
import { connect } from 'react-redux';
import { getRiwayatAbsensiKaryawan } from '../../store/actions/absenAction';
import AbsensiSelect from '../../components/Input/Select';
import { Grid } from '@material-ui/core';
import DatePickerComponent from '../../components/Input/DatePickerComponent';
import IzinTabs from '../../components/Tabs/IzinTabs';

const Absensi = ({ getRiwayatAbsensiKaryawan, dataAbsen }) => {
    const absenState = useSelector((state) => state.absen);
    const dispatch = useDispatch();
    const [absenMasuk, setAbsenMasuk] = useState(true);
    const [absenKeluar, setAbsenKeluar] = useState(true);

    const handleAddAbsen = () => {
        dispatch(addAbsenMasuk({}));
    };

    const handleAddAbsenKeluar = () => {
        dispatch(addAbsenKeluar({}));
    };

    const masukButton = () => {
        setAbsenMasuk(false);
        handleAddAbsen();
        setAbsenKeluar(false);
    };

    const keluarButton = () => {
        setAbsenKeluar(true);
        handleAddAbsenKeluar();
        setAbsenMasuk(true);
    };

    // const clearAbsen = () => {
    //     dispatch({
    //         type: 'CLEAR_ABSEN_STATE',
    //         payload: []
    //     });
    // };

    // clearAbsen();
    const getData = () => {
        var dateFormat = JSON.stringify(search.tanggal);
        dateFormat = dateFormat.slice(1, 8);
        dateFormat = dateFormat.split('-').reverse().join('-');
        dateFormat = `?bulan_tahun=${dateFormat}`;

        let params = dateFormat;

        getRiwayatAbsensiKaryawan(params);
    };

    useEffect(() => {
        getData();
    }, []);

    const currentTime = new Date();
    const days = ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'];
    const hari = days[currentTime.getDay()];
    const hariAngka = currentTime.getDate();
    const tanggal = `${hariAngka} ${hari}`;

    const [filteredKehadiran, setFilteredKehadiran] = useState('');
    const [filteredTanggal, setFilteredTanggal] = useState('');

    const handleKehadiranChange = (event) => {
        setFilteredKehadiran(event.target.value);
        setFilteredTanggal('');
    };

    const months = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember'
    ];

    const statusItem = [
        { value: 'semua', text: 'Semua' },
        { value: 'hadir', text: 'Tepat Waktu' },
        { value: 'terlambat', text: 'Terlambat' },
        { value: 'tidak hadir', text: 'Tidak Hadir' }
    ];

    const [search, setSearch] = useState({ tanggal: new Date(), status: '' });
    const [status = '', setStatus] = useState('');

    useEffect(() => {
        getData();
    }, [search]);

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => {
        setOpenModal(!openModal);
    };

    return (
        <div>
            <Sidebar>
                <Title title="Absensi" icon handleIcon={handleOpenModal} subtitle={<IzinTabs type="absen" />} />

                <div className="absen-dropdown-container mt-5">
                    <Grid item sm={6} xs={12}>
                        <AbsensiSelect
                            dataSelect={statusItem}
                            label="Status"
                            state={status}
                            setState={setStatus}
                            name="status"
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <DatePickerComponent
                            state={search}
                            setState={setSearch}
                            name="tanggal"
                            tipe="MMMM yyyy"
                        />
                    </Grid>
                </div>

                <div className="home-card-container">
                    {absenState.dataAbsen
                        ?.filter((item) => {
                            switch (status.status) {
                                case 'tidak hadir':
                                    return (
                                        item?.status === 'tidak hadir' ||
                                        item?.status === ''
                                    );
                                case 'semua':
                                    return true; // don't filter if status is "Semua"
                                default:
                                    return (
                                        item?.status === status.status ||
                                        status === ''
                                    );
                            }
                        })

                        .map((item, index) => (
                            <CardMessages
                                key={index}
                                isAbsent={true}
                                waktuMasuk={item?.jam_masuk || ''}
                                waktuKeluar={item?.jam_pulang || ''}
                                tanggal={item?.tanggal || ''}
                                kehadiran={item?.status || ''}
                                code={item?.absensi_karyawan_code || ''}
                            />
                        ))}
                </div>
            </Sidebar>
            <ModalPreview open={openModal} handleClose={handleOpenModal} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        dataAbsen: state.dataAbsen
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getRiwayatAbsensiKaryawan: (params) =>
            dispatch(getRiwayatAbsensiKaryawan(params))
        // delAturTitikAbsensi: (code) => dispatch(delAturTitikAbsensi(code))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Absensi);
