import moment from 'moment';

// export function formatDate(date) {
//     let d = new Date(date),
//         month = '' + (d.getMonth() + 1),
//         day = '' + d.getDate(),
//         year = d.getFullYear();

//     if (month.length < 2) month = '0' + month;
//     if (day.length < 2) day = '0' + day;

//     return [year, month, day].join('-');
// }

export function formatDate(year) {
    const d = new Date();
    const fullYear = d.getFullYear();
    const getAcademicYear = fullYear - +year;

    let academicYear;
    switch (getAcademicYear) {
        case 0:
            academicYear = 'Latest';
            break;

        case 1:
            academicYear = 'First';
            break;

        case 2:
            academicYear = 'Second';
            break;

        case 3:
            academicYear = 'Third';
            break;

        case 4:
            academicYear = 'Fourth';
            break;

        case 5:
            academicYear = 'Fifth';
            break;

        case 6:
            academicYear = 'Sixth';
            break;

        default:
            academicYear = 'Dropped Out';
            break;
    }

    return academicYear;
}

export const convertToLocal = (date, format) => {
    let res = moment
        .utc(date)
        .local()
        .format(format || 'YYYY-MM-DD HH:mm:ss');
    return res;
};

export const formatDateName = (dateString) => {
    const weekdays = [
        'Minggu',
        'Senin',
        'Selasa',
        'Rabu',
        'Kamis',
        'Jumat',
        'Sabtu'
    ];
    const date = new Date(dateString);
    const dayName = weekdays[date.getDay()];
    const dayNumber = date.getDate();
    return `${dayNumber} ${dayName?.slice(0, 3)}`;
};
