import { useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

import IzinCutiHeader from '../IzinCutiHeader';

import CardIzinCuti from '../../../components/Card/CardIzinCuti';
import MobileNavigation from '../../../components/navigation/MobileNavigation';
import '../../../assets/style/mobile-size.css';
import { getIzinUser } from '../../../store/actions/izinCutiAction';

const IzinIndex = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { data_izin } = useSelector((state) => state.izin);

    useLayoutEffect(() => {
        dispatch(getIzinUser());
    }, []);

    const useStyles = makeStyles({
        dialog: {
            width: '98%',
            height: 'fit-content',
            scrollbarColor: 'transparent',
            scrollbarWidth: '0px',
            overflow: 'hidden'
        },
        backDrop: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
        }
    });
    const classes = useStyles();

    const [state, setState] = useState({
        name: '',
        cuti_code: '',
        unit_kerja: '',
        status: '',
        tanggal_mulai: null,
        tanggal_akhir: null,
        tanggal_akhir_modal: null,
        keterangan: '',
        alasan: '',
        open: false
    });

    let currentStatus = {};

    if (state.status === 'diproses') {
        currentStatus = {
            bgStatus: 'bg-warning',
            txtStatus: 'Diproses',
            txtColor: 'text-warning'
        };
    } else if (state.status === 'diizinkan') {
        currentStatus = {
            bgStatus: 'bg-success',
            txtStatus: 'Diizinkan',
            txtColor: 'text-success'
        };
    } else if (state.status === 'ditolak') {
        currentStatus = {
            bgStatus: 'bg-danger',
            txtStatus: 'Ditolak',
            txtColor: 'text-danger'
        };
    }

    return (
        <div className="i-ultra-container mx-auto">
            <IzinCutiHeader />
            <div className="position-relative mt-130 mb-120">
                {data_izin.length !== 0 ? (
                    data_izin.map((data) => (
                        <CardIzinCuti
                            setState={setState}
                            id={data.id}
                            izin={data.izin}
                            status={data.status}
                            tanggal_mulai={new Date(data.tanggal_mulai)}
                            tanggal_akhir={new Date(data.tanggal_akhir)}
                            keterangan={data.keterangan}
                            jenis_izin={data.jenis_izin}
                            data={data}
                        />
                    ))
                ) : (
                    <div className="mx-auto my-8 text-center">
                        data izin kosong
                    </div>
                )}
                <div className="my-4"></div>
            </div>
            <Dialog
                open={state.open}
                onClose={() => setState((prev) => ({ ...prev, open: false }))}
                aria-labelledby="responsive-dialog-title"
                classes={{
                    paper: classes.dialog
                }}
                BackdropProps={{
                    classes: {
                        root: classes.backDrop
                    }
                }}
            >
                <Grid
                    container
                    spacing={3}
                    justifyContent="space-between"
                    className="px-3 my-2"
                >
                    <Grid
                        item
                        xs={12}
                        className="fs-16 font-weight-bold text-black mb-1"
                    >
                        Detail Izin
                    </Grid>
                    <Grid item xs={6}>
                        <p className="font-weight-medium fs-11 text-abu-muda">
                            Nama Karyawan
                        </p>
                        <p className="font-weight-medium fs-13">{state.name}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <p className="font-weight-medium fs-11 text-abu-muda">
                            Unit Kerja
                        </p>
                        <p className="font-weight-medium fs-13">
                            {localStorage.getItem('unit_kerja')}
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <p className="font-weight-medium fs-11 text-abu-muda">
                            Status
                        </p>
                        <div
                            className={`${currentStatus.bgStatus} rounded-lg d-flex justify-content-center w-65`}
                        >
                            <p className="mx-2 fs-12 my-1 text-white">
                                {currentStatus.txtStatus}
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <p className="font-weight-medium fs-11 text-abu-muda">
                            Tanggal
                        </p>
                        <p className="font-weight-medium fs-13">
                            {state.tanggal_mulai === state.tanggal_akhir
                                ? state.tanggal_mulai
                                : state.tanggal_mulai +
                                  ' - ' +
                                  state.tanggal_akhir_modal}
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <p className="font-weight-medium fs-11 text-abu-muda">
                            Keterangan
                        </p>
                        <p className="font-weight-medium fs-13">
                            {state.keterangan}
                        </p>
                    </Grid>
                    {state.status.toLocaleLowerCase() === 'ditolak' && (
                        <Grid item xs={12}>
                            <p className="font-weight-medium fs-11 text-abu-muda">
                                Alasan Ditolak
                            </p>
                            <p className="font-weight-medium fs-13">
                                {state.alasan}
                            </p>
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={12}
                        className="d-flex justify-content-between"
                    >
                        <button
                            className="w-100 rounded-6 text-abu-tua padblock-8 border-1-grey"
                            onClick={() =>
                                setState((prev) => ({
                                    ...prev,
                                    open: false
                                }))
                            }
                        >
                            Tutup
                        </button>
                    </Grid>
                </Grid>
            </Dialog>

            <button
                onClick={() => history.push('izin/ajukan')}
                type="button"
                className="mx-auto btn btn-lg fixed-bottom fs-11 i-btn-bottom i-ultra-container"
            >
                Ajukan Izin
            </button>
            <div>
                <MobileNavigation />
            </div>
        </div>
    );
};

export default IzinIndex;
