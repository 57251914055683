import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../assets/style/mobile-size.css';

import {
    IcHome,
    IcProfile,
    IcDocument,
    IcWallet,
    IcCalendar
} from '../../assets';

const MobileNavigation = ({ user }) => {
    const [path, setPath] = useState('');

    useEffect(() => {
        const path = window.location.pathname;
        setPath(path);
    }, []);

    return (
        <div className="position-fixed menu-mobile border ">
            <Container className="d-flex align-items-center justify-content-between px-4">
                <div
                    className={
                        path === '/' ? 'navbar-active-menu' : 'navbar-menu'
                    }
                >
                    <Link
                        to="/"
                        className="d-flex flex-column align-items-center justify-content-center item-menu "
                    >
                        <img
                            alt="icon menu"
                            src={IcHome}
                            className={
                                path === '/' ? 'icon-active-color' : null
                            }
                        />
                        <p
                            className={`m-0 ${
                                path === '/' ? 'text-primaryy' : ''
                            }`}
                        >
                            Beranda
                        </p>
                    </Link>
                </div>
                <div
                    className={
                        path === '/lembur' || path === '/Absensi'
                            ? 'navbar-active-menu'
                            : 'navbar-menu'
                    }
                >
                    <Link
                        to="/Absensi"
                        className="d-flex flex-column align-items-center justify-content-center item-menu "
                    >
                        <img
                            alt="icon menu"
                            src={IcCalendar}
                            className={
                                path === '/Absensi' || path === '/lembur'
                                    ? 'icon-active-color'
                                    : null
                            }
                        />
                        <p
                            className={`m-0 ${
                                path === '/Absensi' || path === '/lembur'
                                    ? 'text-primaryy'
                                    : ''
                            }`}
                        >
                            Absensi
                        </p>
                    </Link>
                </div>
                <div
                    className={
                        path === '/gaji' ? 'navbar-active-menu' : 'navbar-menu'
                    }
                >
                    <Link
                        to="/gaji"
                        className="d-flex flex-column align-items-center justify-content-center item-menu "
                    >
                        <img
                            alt="icon menu"
                            src={IcWallet}
                            className={
                                path === '/gaji' ? 'icon-active-color' : null
                            }
                        />
                        <p
                            className={`m-0 ${
                                path === '/gaji' ? 'text-primaryy' : ''
                            }`}
                        >
                            Gaji
                        </p>
                    </Link>
                </div>
                <div
                    className={
                        path === '/izin' || path === '/cuti'
                            ? 'navbar-active-menu'
                            : 'navbar-menu'
                    }
                >
                    <Link
                        to="/izin"
                        className="d-flex flex-column align-items-center justify-content-center item-menu "
                    >
                        <img
                            alt="icon menu"
                            src={IcDocument}
                            className={
                                path === '/izin' || path === '/cuti'
                                    ? 'icon-active-color'
                                    : null
                            }
                        />
                        <p
                            className={`m-0 ${
                                path === '/izin' || path === '/cuti'
                                    ? 'text-primaryy'
                                    : ''
                            }`}
                        >
                            Izin Cuti
                        </p>
                    </Link>
                </div>
                <div
                    className={
                        path === '/akun' ? 'navbar-active-menu' : 'navbar-menu'
                    }
                >
                    <a
                        // to={`/profile/${user?.username}`}
                        href="/akun"
                        className="d-flex flex-column align-items-center justify-content-center item-menu"
                    >
                        <img alt="icon menu" src={IcProfile} />
                        {/* <p
                            className={`m-0 ${
                                path === '/profile/' + user?.username
                                    ? 'active'
                                    : ''
                            }`}
                        >
                            Akun
                        </p> */}
                        <p className={`m-0 ${path === '/' ? '' : ''}`}>Akun</p>
                    </a>
                </div>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user.user
    };
};

export default connect(mapStateToProps, null)(MobileNavigation);
