import { GET_LEMBUR } from '../actions/LemburAction';
const initialState = {
    dataLembur: []
};

const lemburReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LEMBUR: {
            return {
                ...state,
                dataLembur: action.payload
            };
        }

        default:
            return state;
    }
};

export default lemburReducer;
