import { useHistory } from 'react-router-dom';
import Title from '../../components/Title/Title';
import '../../assets/style/ubahProfile.css';

import { useDispatch } from 'react-redux';
import { useState, useLayoutEffect } from 'react';
import {
    getDetailKaryawan,
    updateProfileUserMobile
} from '../../store/actions/akunAction';
import Swal from 'sweetalert2';
import SelectText from '../../components/Select/SelectText';
import FotoProfileHandler from './FotoProfileHandler';

const UbahProfile = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const id = localStorage.getItem('users_code');

    const [state, setState] = useState({
        foto_profile: '',
        img_profile_preview: '',
        name: '',
        email: '',
        telepon: '',
        unit_kerja_code: '',
        jenis_kelamin: '',
        users_code: ''
    });

    const [stateFoto, setStateFoto] = useState({
        foto_profile: '',
        img_profile_preview: ''
    });

    useLayoutEffect(() => {
        getDetailKaryawan(id).then((res) => {
            let data = res.data?.data[0];
            console.log(data);
            setState((prev) => ({
                ...prev,
                ...data
            }));
        });
    }, []);

    // handle change
    const handleChange = (id) => (e) => {
        const { value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [id]: value
        }));

        console.log(value);
    };

    const handleChangeProfile = (file, path) => {
        setState((prev) => ({
            ...prev,
            foto_profile: file,
            img_profile_preview: path
        }));

        setStateFoto((prev) => ({
            ...prev,
            foto_profile: file,
            img_profile_preview: path
        }));
    };

    const handleSubmit = () => {
        const data = {
            users_code: id,
            name: state.name,
            email: state.email,
            unit_kerja: state.unit_kerja_code,
            telepon: state.telepon,
            jenis_kelamin: state.jenis_kelamin,
            foto_profile: stateFoto.foto_profile
        };

        dispatch(updateProfileUserMobile(data))
            .then(() => {
                Swal.fire('Success!', 'Data user berhasil disimpan', 'success');
                history.push('/akun');
            })
            .catch((err) => {
                let error = err?.response?.data;
                Swal.fire(
                    Array.isArray(error?.data)
                        ? error?.data[0]
                        : 'Gagal menyimpan data, coba beberapa saat lagi',
                    false
                );
            });
    };

    const title = (
        <div onClick={() => history.push('/akun')} className="d-flex">
            <i className="ri-arrow-left-line"></i>
            <p className="mx-2">Ubah Profile</p>
        </div>
    );

    return (
        <div className="i-ultra-container mx-auto p-0">
            <Title title={title} />
            <div class="container mt-5 border">
                {/* picture */}
                <div class="d-flex justify-content-center starter-margin position-relative">
                    <FotoProfileHandler
                        name="foto_profile"
                        uploadFoto={handleChangeProfile}
                        preview={state.foto_profile}
                        // setState={setState}
                        CardStyle={{
                            width: '85px',
                            height: '85px',
                            borderRadius: '100%',
                            border: '2px solid #72BE42'
                        }}
                    />
                </div>

                <div>
                    <p>
                        <small>Nama</small>
                    </p>
                    <div class="input-group mb-3">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Username"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={state.name}
                            onChange={handleChange('name')}
                            name="name"
                        ></input>
                    </div>
                </div>

                <div>
                    <p>
                        <small>Email</small>
                    </p>
                    <div class="input-group mb-3">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Username"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={state.email}
                            onChange={handleChange('email')}
                            name="email"
                        ></input>
                    </div>
                </div>

                <div>
                    <p>
                        <small>Nomer Telepon</small>
                    </p>
                    <div class="input-group mb-3">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Username"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={state.telepon}
                            onChange={handleChange('telepon')}
                            name="telepon"
                        ></input>
                    </div>
                </div>

                <div>
                    <p>
                        <small>Unit Kerja</small>
                    </p>
                    <div class="input-group mb-3">
                        <SelectText
                            value={state.unit_kerja_code}
                            label="Unit Kerja"
                            name="unit_kerja_code"
                            handleChange={handleChange('unit_kerja_code')}
                        />

                        {/* <SelectText
                            label="Unit Kerja"
                            handleChange={handleChange('unit_kerja')}
                            value={state.unit_kerja}
                        /> */}
                    </div>
                </div>
                <div>
                    <p>
                        <small>Jenis Kelamin</small>
                    </p>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="jenis_kelamin"
                            id="jenisKelamin1"
                            checked={state.jenis_kelamin === 'laki-laki'}
                            onClick={() =>
                                setState((prev) => ({
                                    ...prev,
                                    jenis_kelamin: 'laki-laki'
                                }))
                            }
                        ></input>
                        <label class="form-check-label" for="jenisKelamin">
                            Laki-laki
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="jenis_kelamin"
                            id="jenisKelamin2"
                            checked={state.jenis_kelamin === 'perempuan'}
                            onClick={() =>
                                setState((prev) => ({
                                    ...prev,
                                    jenis_kelamin: 'perempuan'
                                }))
                            }
                        ></input>
                        <label class="form-check-label" for="jenisKelamin">
                            Perempuan
                        </label>
                    </div>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        class="btn btn-primary btn-block mt-4 text-white btn-simpan"
                    >
                        Simpan
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UbahProfile;
