import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addAbsenMasuk, addAbsenKeluar } from '../../store/actions/absenAction';
import { Sidebar, CardMessages, Title, ModalPreview } from '../../components';
import '../../assets/style/absensipage.css';
import { connect } from 'react-redux';
import AbsensiSelect from '../../components/Input/Select';
import { Grid, Button } from '@material-ui/core';
import DatePickerComponent from '../../components/Input/DatePickerComponent';
import Link from 'react-router-dom/Link';
import IzinTabs from '../../components/Tabs/IzinTabs';
import CardIzinCuti from '../../components/Card/CardIzinCuti';
import ModalEditAvatar from '../../components/modals/ModalEditAvatar';

import { getLembur } from '../../store/actions/LemburAction';

const Lembur = ({ getLembur, dataLembur }) => {
    const lemburState = useSelector((state) => state.lembur);
    const dispatch = useDispatch();
    const [absenMasuk, setAbsenMasuk] = useState(true);
    const [absenKeluar, setAbsenKeluar] = useState(true);

    const handleAddAbsen = () => {
        dispatch(addAbsenMasuk({}));
    };

    const handleAddAbsenKeluar = () => {
        dispatch(addAbsenKeluar({}));
    };

    const masukButton = () => {
        setAbsenMasuk(false);
        handleAddAbsen();
        setAbsenKeluar(false);
    };

    const keluarButton = () => {
        setAbsenKeluar(true);
        handleAddAbsenKeluar();
        setAbsenMasuk(true);
    };

    // const clearAbsen = () => {
    //     dispatch({
    //         type: 'CLEAR_ABSEN_STATE',
    //         payload: []
    //     });
    // };

    // clearAbsen();
    const getData = () => {
        var dateFormat = JSON.stringify(search.tanggal);
        dateFormat = dateFormat.slice(1, 8);
        dateFormat = dateFormat.split('-').reverse().join('-');
        dateFormat = `?bulan_tahun=${dateFormat}`;

        let params = dateFormat;

        getLembur(params);
    };

    useEffect(() => {
        getData();
    }, []);

    const currentTime = new Date();
    const days = ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'];
    const hari = days[currentTime.getDay()];
    const hariAngka = currentTime.getDate();
    const tanggal = `${hariAngka} ${hari}`;

    const [filteredKehadiran, setFilteredKehadiran] = useState('');
    const [filteredTanggal, setFilteredTanggal] = useState('');

    const handleKehadiranChange = (event) => {
        setFilteredKehadiran(event.target.value);
        setFilteredTanggal('');
    };

    const months = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember'
    ];

    const statusItem = [
        { value: 'semua', text: 'Semua' },
        { value: 'diterima', text: 'Diterima' },
        { value: 'diproses', text: 'Diproses' },
        { value: 'ditolak', text: 'Ditolak' }
    ];

    const [search, setSearch] = useState({ tanggal: new Date(), status: '' });
    const [status = '', setStatus] = useState('');

    useEffect(() => {
        getData();
    }, [search]);

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => {
        setOpenModal(!openModal);
    };

    const [openModalUpload, setOpenModalUpload] = useState('');
    const handleOpenModalUpload = (code) => {
        setOpenModalUpload(code);
    };
    const handleOpenModalUploadClose = () => {
        setOpenModalUpload('');
        getData();
    };
    console.log(lemburState.dataLembur);

    return (
        <div>
            <Sidebar>
                <Title title="Lembur" icon handleIcon={handleOpenModal}  subtitle={<IzinTabs type="absen" />}/>
<div className='mt-55' />
                <Grid item sm={12} xs={24} >
                    <Link className="lembur-button" to="/pengajuan_lembur">
                        <Button>Ajukan Lembur</Button>
                    </Link>
                </Grid>
                <div className="absen-dropdown-container">
                    <Grid item sm={6} xs={12}>
                        <AbsensiSelect
                            dataSelect={statusItem}
                            label="Status"
                            state={status}
                            setState={setStatus}
                            name="status"
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <DatePickerComponent
                            state={search}
                            setState={setSearch}
                            name="tanggal"
                            tipe="MMMM yyyy"
                        />
                    </Grid>
                </div>

                <div className="home-card-container">
                    { lemburState.dataLembur.length !== 0 ? (lemburState.dataLembur
                        ?.filter((item) => {
                            switch (status.status) {
                                case 'ditolak':
                                    return item?.status === 'ditolak';
                                case 'diterima':
                                    return item?.status === 'diterima';
                                case 'diproses':
                                    return item?.status === 'diproses';
                                case 'semua':
                                    return true; // don't filter if status is "Semua"
                                default:
                                    return (
                                        item?.status === status.status ||
                                        status === ''
                                    );
                            }
                        })

                        .map((item, index) => (
                            <CardIzinCuti
                                key={index}
                                // isAbsent={true}
                                // waktuMasuk={item?.absen_mulai || ''}
                                // waktuKeluar={item?.absen_akhir || ''}
                                tanggal={item?.tanggal || ''}
                                status={item.status}
                                code={item?.lembur_code || ''}
                                keterangan={item?.keterangan}
                                handleOpenModal={handleOpenModalUpload}
                                foto={item?.foto_bukti || ''}
                                lembur
                            />
                        ))): ( <div className="mx-auto my-8 text-center">
                        data Lembur kosong
                    </div> )  }
                    
                </div>
            </Sidebar>
            <ModalPreview
                open={openModal}
                handleClose={handleOpenModal}
                lembur
            />
            <ModalEditAvatar
                open={openModalUpload}
                handleClose={handleOpenModalUploadClose}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        dataLembur: state.dataLembur
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getLembur: (params) => dispatch(getLembur(params))
        // delAturTitikAbsensi: (code) => dispatch(delAturTitikAbsensi(code))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Lembur);
