import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import '../../assets/style/mobile-size.css';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)'
    }
});

export default function CardIzinCuti({
    izin = true,
    status = 'diproses',
    tanggal_mulai,
    tanggal_akhir,
    keterangan,
    id,
    setState = '',
    jenis_izin,
    data,
    lembur,
    tanggal,
    handleOpenModal,
    code,
    foto
}) {
    const classes = useStyles();
    let currentStatus = {};

    if (status === 'diproses') {
        currentStatus = {
            bgStatus: 'bg-warning',
            txtStatus: 'Diproses',
            txtColor: 'text-warning'
        };
    } else if (status === 'ditolak') {
        currentStatus = {
            bgStatus: 'bg-danger',
            txtStatus: 'Ditolak',
            txtColor: 'text-danger'
        };
    } else {
        currentStatus = {
            bgStatus: 'bg-success',
            txtStatus: 'Diizinkan',
            txtColor: 'text-success'
        };
    }

    const handleTgl = (date) => {
        var dataReturn = String(date)
            .split(' ')
            .filter((item, index) => index < 3);

        [dataReturn[1], dataReturn[2]] = [dataReturn[2], dataReturn[1]];

        dataReturn.splice(1, 0, ', ');
        dataReturn = dataReturn.map((data, index) => {
            if (index === 3) {
                return ' ' + data;
            }
            return data;
        });

        if (dataReturn[0] === 'Sun') {
            dataReturn[0] = 'Min';
        } else if (dataReturn[0] === 'Mon') {
            dataReturn[0] = 'Sen';
        } else if (dataReturn[0] === 'Tue') {
            dataReturn[0] = 'Sel';
        } else if (dataReturn[0] === 'Wed') {
            dataReturn[0] = 'Rab';
        } else if (dataReturn[0] === 'Thu') {
            dataReturn[0] = 'Kam';
        } else if (dataReturn[0] === 'Fri') {
            dataReturn[0] = 'Jum';
        } else if (dataReturn[0] === 'Sat') {
            dataReturn[0] = 'Sab';
        }

        return dataReturn.join('');
    };

    const handleOpen = async () => {
        const name = await localStorage.getItem('username');
        if (izin) {
            setState((prev) => ({
                ...prev,
                status,
                tanggal_mulai: handleTgl(tanggal_mulai),
                tanggal_akhir: handleTgl(tanggal_akhir),
                tanggal_akhir_modal:
                    handleTgl(tanggal_akhir) +
                    ' ' +
                    tanggal_akhir.getFullYear(),
                keterangan,
                open: true,
                name,
                alasan: data.alasan
            }));
        } else {
            setState((prev) => ({
                ...prev,
                status,
                tanggal_mulai: handleTgl(tanggal_mulai),
                tanggal_akhir: handleTgl(tanggal_akhir),
                tanggal_akhir_modal:
                    handleTgl(tanggal_akhir) +
                    ' ' +
                    tanggal_akhir.getFullYear(),
                keterangan,
                open: true,
                name,
                cuti_code: id,
                alasan: data.alasan
            }));
        }
    };

    if (izin === true) {
        return (
            <Card
                className={
                    lembur
                        ? ' my-3 mx-3 i-card px-2  w-90'
                        : ' my-3 mx-3 i-card px-2 '
                }
                onClick={handleOpen}
                key={id}
            >
                <CardContent>
                    <div className="d-flex justify-content-between ">
                        <div>
                            <p
                                className={
                                    lembur
                                        ? ` fs-14 font-weight-medium`
                                        : `${currentStatus.txtColor} fs-14 font-weight-medium`
                                }
                            >
                                {lembur ? tanggal : jenis_izin}
                            </p>
                        </div>
                        <div
                            className={`${currentStatus.bgStatus} rounded-lg d-flex justify-content-center`}
                            style={{ width: '65px' }}
                        >
                            <p className="mx-2 fs-12 my-2 text-white">
                                {currentStatus.txtStatus}
                            </p>
                        </div>
                    </div>

                    {lembur ? null : (
                        <p
                            className={`${classes.title} my-4 fs-16 text-abu-tua`}
                        >
                            {handleTgl(tanggal_mulai) ===
                            handleTgl(tanggal_akhir)
                                ? handleTgl(tanggal_mulai) +
                                  ' ' +
                                  new Date(tanggal_akhir).getFullYear()
                                : `${handleTgl(tanggal_mulai)} - ${handleTgl(
                                      tanggal_akhir
                                  )} ${new Date(tanggal_akhir).getFullYear()}`}
                        </p>
                    )}
                    <div
                        className="d-flex justify-content-between"
                        style={{ alignItems: 'flex-end' }}
                    >
                        <div>
                            <p className="fs-12 text-black font-weight-medium">
                                Keterangan
                            </p>
                            <p
                                className="fs-12 text-abu-tua"
                                style={{ wordWrap: 'break-word' }}
                            >
                                {keterangan}
                            </p>
                        </div>
                        {lembur && status === 'diterima' && !foto ? (
                            <Button
                                variant="outlined"
                                color="primary"
                                className="rounded-8"
                                size="small"
                                onClick={() => handleOpenModal(code)}
                            >
                                Upload Bukti
                            </Button>
                        ) : lembur && status === 'diterima' && foto ? (
                            <p
                                className="fs-12 text-abu-tua"
                                style={{ wordWrap: 'break-word' }}
                            >
                                Bukti Sudah Diupload
                            </p>
                        ) : null}
                    </div>
                </CardContent>
            </Card>
        );
    } else if (izin === false) {
        return (
            <Card
                onClick={() => handleOpen()}
                className=" my-3 mx-3 i-card px-2"
                key={id}
            >
                <CardContent>
                    <div
                        className={`${currentStatus.bgStatus} rounded-lg d-flex justify-content-center`}
                        style={{ width: '65px' }}
                    >
                        <p className="mx-2 fs-12 my-2 text-white">
                            {currentStatus.txtStatus}
                        </p>
                    </div>

                    <p className={`${classes.title} my-4 fs-16 text-abu-tua`}>
                        {handleTgl(tanggal_mulai) === handleTgl(tanggal_akhir)
                            ? handleTgl(tanggal_mulai) +
                              ' ' +
                              new Date(tanggal_akhir).getFullYear()
                            : `${handleTgl(tanggal_mulai)} - ${handleTgl(
                                  tanggal_akhir
                              )} ${new Date(tanggal_akhir).getFullYear()}`}
                    </p>
                    <p className="fs-12 text-black font-weight-medium">
                        Keterangan
                    </p>
                    <p
                        className="fs-12 text-abu-tua"
                        style={{ wordWrap: 'break-word' }}
                    >
                        {keterangan}
                    </p>
                </CardContent>
            </Card>
        );
    }
}
