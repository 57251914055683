import { Dialog, Slide, Button } from '@material-ui/core';
import React from 'react';

const ModalPreview = ({ open, handleClose, preview, lembur }) => {
    const status = lembur
        ? ['Disetujui', 'Diproses', 'Ditolak']
        : ['Tepat Waktu', 'Telat Hadir', 'Tidak Hadir', 'Lembur'];
    return (
        <div>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={open}
                onClose={handleClose}
                TransitionComponent={Slide}
                TransitionProps={{
                    direction: 'up',
                    mountOnEnter: true,
                    unmountOnExit: true
                }}
                PaperProps={{
                    style: {
                        position: 'absolute',
                        bottom: '-32px',
                        width: '100vw',
                        height: 'max-content',
                        paddingBlock: '20px'
                    }
                }}
            >
                <div className="modal-container ">
                    <h2>Status {lembur ? 'Lembur' : 'Kehadiran'}</h2>
                    {lembur ? (
                        <p>
                            Perbedaan warna pada lembur dapat membantu untuk
                            mengetahui status lembur pada hari itu.
                        </p>
                    ) : (
                        <p>
                            Perbedaan warna pada absensi dapat membantu untuk
                            mengetahui status kehadiran pada hari itu.
                        </p>
                    )}

                    <div className="status-container">
                        {status.map((item, index) => (
                            <div key={index}>{item}</div>
                        ))}
                    </div>
                    <Button onClick={handleClose}>Tutup</Button>
                </div>
            </Dialog>
        </div>
    );
};

export default ModalPreview;
