import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import { IcCalendar } from '../../assets';

function DatePickerComponent({
    required = false,
    state,
    setState,
    name,
    tipe = 'dd/MM/yyyy',
    format = 'long',
    scaleY = '1'
}) {
    const DatePicker = withStyles((theme) => ({
        root: {
            '& .MuiOutlinedInput-input': {
                padding: '12px',
                backgroundColor: '#fbfbfb',
                marginRight: '-13px',

                width: '120%'
            }
        }
    }))((props) => <KeyboardDatePicker color="default" {...props} />);

    const handleTanggal = (e) => {
        const event = new Date(e);

        let dateFormat = JSON.stringify(event);

        dateFormat = dateFormat.slice(1, 11);

        setState({
            ...state,
            [name]: dateFormat
        });
    };

    return (
        <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    required={required}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    format={tipe}
                    control={<KeyboardDatePicker />}
                    value={state[name]}
                    InputAdornmentProps={{
                        position: 'end'
                    }}
                    onChange={handleTanggal}
                    keyboardIcon={
                        <div>
                            <img src={IcCalendar} alt="kalender" />
                        </div>
                    }
                    className="w-full"
                    KeyboardButtonProps={{
                        style: {
                            background: '#fbfbfb',
                            marginRight: '-15px',
                            width: '35px',
                            height: '41px',
                            paddingTop: '10px',
                            borderRadius: '0px 5px 5px 0px'
                        }
                    }}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
}
export default DatePickerComponent;
