import React, { useEffect, useState } from 'react';
import { Sidebar, Title } from '../../components';
import '../../assets/style/inputabsensi.css';
import 'leaflet/dist/leaflet.css';
import { useDispatch } from 'react-redux';
import L from 'leaflet';
import iconMarker from 'leaflet/dist/images/marker-icon.png';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { IcBarcode, IcSelfie, IcLocate } from '../../assets';
import {
    addKaryawanAbsensi,
    editKaryawanAbsensi,
    getLokasiAbsensi
} from '../../store/actions/absenAction';
import Swal from 'sweetalert2';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getCurrentTime } from '../../utils/currentTime';
import { useLocation } from 'react-router-dom';

import {
    MapContainer,
    TileLayer,
    useMap,
    Marker,
    CircleMarker
} from 'react-leaflet';
import { Icon } from 'leaflet';
import { useSelector } from 'react-redux';

export default function InputAbsensi() {
    const { id, code } = useParams();
    const location = useLocation();
    const waktuMasuk = location.state?.waktuMasuk;
    const [data, setData] = useState('Not Found');
    const icon = L.icon({
        iconRetinaUrl: iconRetina,
        iconUrl: iconMarker,
        shadowUrl: iconShadow
    });

    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [acc, setAcc] = useState(0);
    const [coords, setCoords] = useState([0, 0]);
    const [display, setDisplay] = useState('map');

    const dispatch = useDispatch();
    const [submit, setSubmit] = useState(false);
    const history = useHistory();
    const lokasiAbsensi = useSelector(
        (state) => state.absen.lokasiAbsensi.data
    );
    const getDataLokasi = () => {
        dispatch(getLokasiAbsensi());
    };
    useEffect(() => {
        getDataLokasi();
    }, []);

    const successCallback = ({ coords }) => {
        const { latitude, longitude, accuracy } = coords;
        console.log(coords);
        setLatitude(latitude);
        setLongitude(longitude);
        setCoords([latitude, longitude]);
        setAcc(accuracy);
    };

    const pressMap = () =>
        navigator.geolocation.getCurrentPosition(
            successCallback,
            errorCallback
        );

    useEffect(() => {
        pressMap();
    }, []);

    const errorCallback = (error) => {
        alert(error);
    };

    const Map = ({ latitude, longitude }) => {
        const map = useMap();

        useEffect(() => {
            map.panTo([latitude, longitude]);
        }, [latitude, longitude]);

        return null;
    };

    const [zoom, setZoom] = useState(23);

    useEffect(() => {
        setZoom(13);
    }, []);

    const handleZoomEnd = (e) => {
        setZoom(e.target._zoom);
    };

    const center = {
        lat: lokasiAbsensi?.latitude || -6.1753924,
        lng: lokasiAbsensi?.longitude || 106.8271528
    };

    const centerLatLng = L.latLng(center.lat, center.lng);
    const myLocation = L.latLng(latitude, longitude);
    const bufferRadius = lokasiAbsensi?.radius;
    const distance = myLocation.distanceTo(center);
    const isInsideBuffer = distance <= bufferRadius;
    console.log(distance);

    const [color = '#72BE42', setColor] = useState();
    const [fillColor = '#72BE42', setFillColor] = useState();
    const [change = false, setChange] = useState();

    useEffect(() => {
        if (distance < 10000) {
            setChange(true);
            if (isInsideBuffer) {
                setColor('#72BE42');
                setFillColor('#72BE42');
            } else if (!isInsideBuffer) {
                setColor('#FF5C58');
                setFillColor('#FF5C58');
            }
        }
    }, [distance, pressMap, isInsideBuffer]);

    const handleSubmit = async () => {
        try {
            if (isInsideBuffer) {
                setSubmit(true);
                let response;
                switch (id) {
                    case 'Masuk':
                        response = await dispatch(
                            addKaryawanAbsensi({
                                latitude: latitude,
                                longitude: longitude
                            })
                        );
                        break;
                    case 'Keluar':
                        response = await dispatch(
                            editKaryawanAbsensi({
                                absensi_karyawan_code: code,
                                latitude_pulang: latitude,
                                longitude_pulang: longitude
                            })
                        );
                        break;
                    default:
                        throw new Error(`Invalid id: ${id}`);
                }
                Swal.fire({
                    title: 'Success',
                    text: `Data Absensi Berhasil Disimpan`,
                    icon: 'success',
                    iconColor: '#00ACEE',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    history.push('/');
                    setSubmit(false);
                });
            } else {
                Swal.fire({
                    title: 'Error',
                    text: `Anda Tidak Berada Di Area Absensi`,
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false
                });
            }
        } catch (error) {
            const err = error.response.data;
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                timer: 2000,
                showConfirmButton: false
            });
        }
    };
    return (
        <div>
            <Sidebar noNavbar={true}>
                <Title
                    style={{ height: '10vh' }}
                    title={
                        id === 'Masuk_Lembur'
                            ? 'Absen Masuk Lembur'
                            : id === 'Keluar_Lembur'
                            ? 'Absen Keluar  Lembur'
                            : `Absen ${id}`
                    }
                    back
                />

                <div>
                    <MapContainer
                        center={[0, 0]}
                        zoom={zoom}
                        scrollWheelZoom={false}
                        zoomControl={false}
                        onZoomEnd={handleZoomEnd}
                        style={{
                            height: '68vh',
                            width: '100%',
                            position: 'relative',
                            zIndex: 0,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginTop: '30px'
                        }}
                    >
                        <Map latitude={latitude} longitude={longitude} />
                        <Marker position={[latitude, longitude]} icon={icon} />
                        {change && (
                            <CircleMarker
                                center={centerLatLng}
                                radius={bufferRadius}
                                color={color}
                                fillColor={fillColor}
                                fillOpacity={0.2}
                            />
                        )}

                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </MapContainer>
                </div>
                <div className="locate-button">
                    <Button onClick={() => pressMap()}>
                        <img src={IcLocate} alt="" />
                    </Button>
                </div>
                <div className="input-absensi-footer">
                    <div className="input-absensi-footer-Button">
                        <Button
                            color="primary"
                            style={{ color: 'white' }}
                            type="submit"
                            variant="contained"
                            className="mb-4 w-100 py-2 btn-auth"
                            disabled={submit}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            {submit ? (
                                <CircularProgress
                                    size={24}
                                    className="text-salmon"
                                />
                            ) : id === 'Masuk_Lembur' ? (
                                'Absen Masuk Lembur'
                            ) : id === 'Keluar_Lembur' ? (
                                'Absen Keluar  Lembur'
                            ) : (
                                `Absen ${id}`
                            )}
                        </Button>
                    </div>

                    <div className="absensi-footer-bottom">
                        <div className="absensi-input-card ">
                            <div>
                                <h2>Jam Masuk</h2>
                                <h1>
                                    {id === 'Masuk'
                                        ? getCurrentTime()
                                        : waktuMasuk?.substring(0, 5)}
                                </h1>
                            </div>
                            <div className="w-1px  h-50px  bg-grey-border br-2  "></div>
                            <div>
                                <h2>Jam Keluar</h2>
                                <h1>
                                    {id === 'Masuk'
                                        ? '--:--'
                                        : getCurrentTime()}
                                </h1>
                            </div>
                        </div>
                        <div className="w-1px  h-50px  bg-grey-border br-2  "></div>

                        <div className="input-absensi-button-wrapper">
                            <Link
                                to={
                                    code
                                        ? {
                                              pathname: `/InputAbsensi_qr/${id}/${code}`,
                                              state: { waktuMasuk }
                                          }
                                        : `/InputAbsensi_qr/${id}`
                                }
                            >
                                <div className="input-absensi-button-container">
                                    <div className="input-absensi-button">
                                        <img src={IcBarcode} alt="" />
                                    </div>
                                    Barcode
                                </div>
                            </Link>
                            <Link
                                to={
                                    code
                                        ? {
                                              pathname: `/InputAbsensi_selfie/${id}/${code}`,
                                              state: { waktuMasuk }
                                          }
                                        : `/InputAbsensi_selfie/${id}`
                                }
                            >
                                <div className="input-absensi-button-container">
                                    <div className="input-absensi-button">
                                        <img src={IcSelfie} alt="" />
                                    </div>
                                    selfie
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </Sidebar>
        </div>
    );
}
