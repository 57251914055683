import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Sidebar, CardMessages, Title } from '../../components';
import '../../assets/style/absensipage.css';
import { connect } from 'react-redux';
import {
    getNotifikasi,
    editNotifikasi
} from '../../store/actions/notifikasiAction';
import {
    IcHome,
    IcProfile,
    IcDocument,
    IcWallet,
    IcCalendar
} from '../../assets';
const Notifikasi = ({ getNotifikasi }) => {
    const dataNotifikasi = useSelector(
        (state) => state.notifikasi.dataNotifikasi
    );
    const dispatch = useDispatch();
    const [absenMasuk, setAbsenMasuk] = useState(true);
    const [absenKeluar, setAbsenKeluar] = useState(true);

    const getData = () => {
        getNotifikasi();
    };

    useEffect(() => {
        getData();
    }, []);

    const currentTime = new Date();

    const pageImage = (pagename) => {
        switch (pagename) {
            case 'Home':
                return IcHome;
            case 'akun':
                return IcProfile;
            case 'izin':
                return IcDocument;
            case 'cuti':
                return IcDocument;
            case 'gaji':
                return IcWallet;
            case 'absensi':
                return IcCalendar;
            default:
                return IcHome;
        }
    };

    const handleRead = async (id) => {
        try {
            await dispatch(editNotifikasi(id));
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <Sidebar>
                <Title title="Notifikasi" />

                <div className="home-card-container">
                    {dataNotifikasi.map((item, index) => (
                        <CardMessages
                            to={item?.url}
                            name={item?.judul}
                            message={item?.isi}
                            time={item?.created_at}
                            picture={pageImage(item?.kategori)}
                            isRead={item?.status === 'dikirim' ? true : false}
                            onClickCard={() => {
                                handleRead(item?.notifikasi_code);
                            }}
                        />
                    ))}
                </div>
            </Sidebar>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        dataNotifikasi: state.notifikasi.dataNotifikasi
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getNotifikasi: () => dispatch(getNotifikasi())
        // delAturTitikAbsensi: (code) => dispatch(delAturTitikAbsensi(code))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Notifikasi);
