import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    CircularProgress,
    TextField,
    Grid
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import InputFileImg from '../Input/InputFileImg';
import Swal from 'sweetalert2';
import { editLembur } from '../../store/actions/LemburAction';
import { useDispatch } from 'react-redux';
import { IcClose } from '../../assets';

const ModalEditAvatar = ({
    open,
    handleClose,
    data,
    handleChange,
    value,
    code
}) => {
    const [selected, setSelected] = useState('');
    const [preview, setPreview] = useState('');
    useEffect(() => {
        setSelected(value);
    }, [value]);
    const [formData, setFormData] = useState({
        foto_bukti: '',
        foto_bukti_preview: ''
    });
    const handleChangeFoto = (file, path) => {
        setFormData((pref) => ({
            ...pref,
            foto_bukti: file,
            foto_bukti_preview: path
        }));
    };

    const handleLink = (e) => {
        const { value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            foto_bukti: value
        }));
    };

    const dispatch = useDispatch();
    const [submit, setSubmit] = useState(false);

    const handleSubmit = async () => {
        await dispatch(
            editLembur({
                lembur_code: open,
                foto_bukti: formData.foto_bukti
            })
        )
            .then(() => {
                Swal.fire({
                    title: 'Success',
                    text: `Data Lembur Berhasil Disimpan`,
                    icon: 'success',
                    iconColor: '#00ACEE',
                    timer: 2000,
                    showConfirmButton: false
                });

                setSubmit(false);

                setFormData({
                    tanggal: new Date().toISOString().slice(0, 10),
                    keterangan: '',

                    waktu_mulai: new Date(),
                    waktu_akhir: new Date(),
                    foto_bukti: '',
                    foto_bukti_preview: ''
                });

                handleClose();
            })
            .catch((err) => {
                let error = err?.response?.data;
                Swal.fire({
                    title: 'Error',
                    text: err.message,
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false
                });
            });
    };

    const [link, setLink] = useState(false);
    useEffect(() => {
        setFormData({
            foto_bukti: '',
            foto_bukti_preview: ''
        });
    }, [open, link]);

    return (
        <Dialog
            scroll="paper"
            maxWidth="sm"
            fullWidth
            open={open}
            onClose={handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle>
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="font-weight-bold mb-0">Upload Foto Bukti</h5>
                    <IconButton onClick={handleClose} size="small">
                        <img src={IcClose} alt="icon close" />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent dividers="paper">
                <Button
                    variant={!link ? 'contained' : 'outlined'}
                    color="primary"
                    className={
                        !link
                            ? 'rounded-8 text-white mb-3 mr-3'
                            : 'rounded-8  mb-3 mr-3'
                    }
                    size="small"
                    onClick={() => setLink(!link)}
                >
                    Image
                </Button>
                <Button
                    variant={!link ? 'outlined' : 'contained'}
                    color="primary"
                    className={
                        !link ? 'rounded-8  mb-3 ' : 'rounded-8 text-white mb-3'
                    }
                    size="small"
                    onClick={() => setLink(!link)}
                >
                    Link
                </Button>
                <div className="">
                    {link ? (
                        <TextField
                            id="outlined-multiline-static"
                            placeholder="Link Bukti"
                            className="text-field-modifier-multiline"
                            variant="standard"
                            InputProps={{
                                disableUnderline: true
                            }}
                            value={formData.foto_bukti}
                            onChange={handleLink}
                        />
                    ) : (
                        <InputFileImg
                            acceptFile="image"
                            uploadFoto={handleChangeFoto}
                            preview={formData.foto_bukti_preview}
                        />
                    )}
                </div>

                {/* <Row>
                    <Col
                        lg="12"
                        md="12"
                        sm="12"
                        xs="12"
                        className="grid-avatar"
                    >
                        {data?.map((item) => (
                            <div
                                key={item.idx}
                                className={`d-flex flex-column align-items-center item-avatar mb-2 ${
                                    selected === item.profile_code
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                <img
                                    src={item.profile_code_img}
                                    alt="img 1"
                                    className={item.name}
                                    onClick={() => {
                                        setSelected(item.profile_code);
                                        setPreview(item.profile_code_img);
                                    }}
                                />
                                <h6 className="font-weight-bold mb-0 mt-2 text-center">
                                    {item.name}
                                </h6>
                            </div>
                        ))}
                    </Col>
                </Row> */}
            </DialogContent>
            <DialogActions className="d-flex justify-content-center align-items-center">
                <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    className="text-white px-5"
                    disabled={submit}
                    onClick={() => {
                        handleSubmit();
                    }}
                >
                    {submit ? (
                        <CircularProgress size={24} className="text-salmon" />
                    ) : (
                        `Submit`
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ModalEditAvatar;
