import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import '../../assets/style/mobile-size.css';

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    };
}

export default function IzinTabs(type) {
    const location = useLocation();

    let izinValue;
    let absenLocation =
        location.pathname === '/Absensi' || location.pathname === '/lembur';
    let gajiLocation =
        location.pathname === '/gaji' || location.pathname === '/reimburse';
    if (location.pathname === '/izin') {
        izinValue = 0;
    } else if (location.pathname === '/cuti') {
        izinValue = 1;
    }
    if (location.pathname === '/Absensi') {
        izinValue = 0;
    } else if (location.pathname === '/lembur') {
        izinValue = 1;
    }

     if (location.pathname === '/gaji') {
        izinValue = 0;
    } else if (location.pathname === '/reimburse') {
        izinValue = 1;
    }

    const [value, setValue] = React.useState(izinValue);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let history = useHistory();

    const handleIzinClick = () => {
        let path = absenLocation ? 'Absensi' : gajiLocation ? 'gaji' : `izin`;
        history.push(path);
    };

    const handleCutiClick = () => {
        let path = absenLocation ? 'lembur': gajiLocation ? 'reimburse' : `cuti`;
        history.push(path);
    };
    return (
        <div className="i-ultra-container bg-light">
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    style={{ marginBlock: '-2px' }}
                    size="small"
                >
                    <Tab
                        onClick={handleIzinClick}
                        label={absenLocation ? 'Absensi' : gajiLocation ? 'Gaji' :'Izin'}
                        {...a11yProps(0)}
                    />
                    <Tab
                        onClick={handleCutiClick}
                        label={absenLocation ? 'Lembur' : gajiLocation ? 'Reimburse' : 'Cuti'}
                        {...a11yProps(1)}
                    />
                </Tabs>
            </AppBar>
        </div>
    );
}
