import { useLayoutEffect, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker } from 'rsuite';

import { getListGaji, getDetailGaji } from '../../store/actions/gajiAction';
import IzinTabs from '../../components/Tabs/IzinTabs';
import MobileNavigation from '../../components/navigation/MobileNavigation';
import '../../assets/style/mobile-size.css';
import Title from '../../components/Title/Title';
import Select from '../../components/Input/Select';
import CardGaji from '../../components/Card/CardGaji';

const GajiIndex = () => {
    const useStyles = makeStyles({
        dialog: {
            width: '98%',
            height: 'fit-content',
            scrollbarColor: 'transparent',
            scrollbarWidth: '0px',
            overflow: 'hidden'
        },
        backDrop: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
        }
    });
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const name = localStorage.getItem('username');

    const handleTgl = (tgl) => {
        var arr = tgl.split('-');

        if (arr[1] === '01') {
            arr[1] = 'Januari';
        } else if (arr[1] === '02') {
            arr[1] = 'Februari';
        } else if (arr[1] === '03') {
            arr[1] = 'Maret';
        } else if (arr[1] === '04') {
            arr[1] = 'April';
        } else if (arr[1] === '05') {
            arr[1] = 'Mei';
        } else if (arr[1] === '06') {
            arr[1] = 'Juni';
        } else if (arr[1] === '07') {
            arr[1] = 'Juli';
        } else if (arr[1] === '08') {
            arr[1] = 'Agustus';
        } else if (arr[1] === '09') {
            arr[1] = 'September';
        } else if (arr[1] === '10') {
            arr[1] = 'Oktober';
        } else if (arr[1] === '11') {
            arr[1] = 'November';
        } else if (arr[1] === '12') {
            arr[1] = 'Desember';
        }

        const data = arr.reverse().join(' ');

        return data;
    };

    const [state, setState] = useState({
        status: '',
        tahun: new Date(),
        unit_kerja: '',
        id: '',
        name: '',
        tanggal_gajian: '',
        total_gaji: '',
        gaji_pokok: '',
        tambahan: '',
        pengurangan: '',
        open: false,
        status_modal: '',
        pdf_path: '',
        disabled: false
    });

    let currentStatus = {};

    if (state.status_modal === 'belum lunas') {
        currentStatus = {
            bgStatus: 'bg-warning',
            txtStatus: 'Belum Lunas'
        };
    } else if (state.status_modal === 'lunas') {
        currentStatus = {
            bgStatus: 'bg-success',
            txtStatus: 'Lunas'
        };
    }

    const { data_list_gaji } = useSelector((state) => state.gaji);

    useLayoutEffect(() => {
        var params = `?status=${
            state.status
        }&tahun=${state.tahun.getFullYear()}`;
        dispatch(getListGaji(params));
    }, [state.status, state.tahun]);

    useLayoutEffect(() => {}, [state.id]);

    useEffect(() => {
        if (data_list_gaji.hasOwnProperty('unit_kerja')) {
            setState((prev) => ({
                ...prev,
                unit_kerja: data_list_gaji.unit_kerja
            }));
        }
    }, [data_list_gaji]);

    const changeDate = (e) => {
        const event = new Date(e);
        setState((prev) => ({
            ...prev,
            tahun: event
        }));
    };

    return (
        <div className="i-ultra-container mx-auto">
                <Title title={'Gaji'} subtitle={<IzinTabs  />} />
                <Grid
                    container
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                    className=" px-3 py-2 "
                    style={{ marginTop: '110px' }}
                >
                    <Grid item xs={6}>
                        <Select
                            dataSelect={[
                                {
                                    text: 'Lunas',
                                    value: 'lunas'
                                },
                                {
                                    text: 'Belum Lunas',
                                    value: 'belum lunas'
                                },
                                {
                                    text: 'Lihat Semua',
                                    value: ''
                                }
                            ]}
                            state={state}
                            setState={setState}
                            label="Status"
                            name="status"
                            width="100%"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DatePicker
                            block
                            value={state.tahun}
                            onChange={changeDate}
                            format="yyyy-MM"
                            placement="autoVerticalEnd"
                        />
                    </Grid>
                </Grid>
            <div className="position-relative mt-10  mb-100">
                {data_list_gaji.hasOwnProperty('data') &&
                data_list_gaji.data.length !== 0 ? (
                    data_list_gaji.data.map((data) => (
                        <div key={data.gaji_code} className="p-0 m-0">
                            <CardGaji
                                id={data.gaji_code}
                                name={name}
                                tanggal_gajian={data.tanggal_pembayaran}
                                unit_kerja={state.unit_kerja}
                                total_gaji={data.total_gaji}
                                status={data.status}
                                gaji_pokok={data.gaji_pokok}
                                tambahan={data.tambahan}
                                pengurangan={data.pengurangan}
                                setState={setState}
                                handleTgl={handleTgl}
                            />
                        </div>
                    ))
                ) : (
                    <div className="mx-auto my-8 text-center">
                        data gaji kosong
                    </div>
                )}
            </div>
            <Dialog
                open={state.open}
                onClose={() => setState((prev) => ({ ...prev, open: false }))}
                aria-labelledby="responsive-dialog-title"
                classes={{
                    paper: classes.dialog
                }}
                BackdropProps={{
                    classes: {
                        root: classes.backDrop
                    }
                }}
            >
                <Grid
                    container
                    spacing={3}
                    justifyContent="space-between"
                    className="px-3 my-2"
                >
                    <Grid
                        item
                        xs={12}
                        className="fs-16 font-weight-bold text-black mb-1"
                    >
                        Detail Gaji
                    </Grid>
                    <Grid item xs={6}>
                        <p className="font-weight-medium fs-11 text-abu-muda">
                            Nama Karyawan
                        </p>
                        <p className="font-weight-medium fs-13">{state.name}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <p className="font-weight-medium fs-11 text-abu-muda">
                            Tanggal Gajian
                        </p>
                        <p className="font-weight-medium fs-13">
                            {handleTgl(state.tanggal_gajian)}
                        </p>
                    </Grid>
                    <Grid item xs={6}>
                        <p className="font-weight-medium fs-11 text-abu-muda">
                            Unit Kerja
                        </p>
                        <p className="font-weight-medium fs-13">
                            {state.unit_kerja}
                        </p>
                    </Grid>
                    <Grid item xs={6}>
                        <p className="font-weight-medium fs-11 text-abu-muda">
                            Status
                        </p>
                        <div
                            className={`${currentStatus.bgStatus} rounded-lg d-flex justify-content-center w-max-content mt-1`}
                        >
                            <p className="mx-3 fs-12 my-2 text-white">
                                {currentStatus.txtStatus}
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <p className="font-weight-medium fs-11 text-abu-muda">
                            Total Gaji
                        </p>
                        <p className="font-weight-medium fs-13">
                            Rp.{' '}
                            {state.total_gaji
                                .toString()
                                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')}
                        </p>
                    </Grid>
                    <Grid item xs={6}>
                        <p className="font-weight-medium fs-11 text-abu-muda">
                            Gaji Pokok
                        </p>
                        <p className="font-weight-medium fs-13">
                            Rp.{' '}
                            {state.gaji_pokok
                                .toString()
                                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')}
                        </p>
                    </Grid>
                    <Grid item xs={6}>
                        <p className="font-weight-medium fs-11 text-abu-muda">
                            Tambahan
                        </p>
                        <p className="font-weight-medium fs-13 text-green">
                            Rp.{' '}
                            {state.tambahan
                                .toString()
                                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')}
                        </p>
                    </Grid>
                    <Grid item xs={6}>
                        <p className="font-weight-medium fs-11 text-abu-muda">
                            Pengurangan
                        </p>
                        <p className="font-weight-medium fs-13 text-red">
                            Rp.{' '}
                            {state.pengurangan
                                .toString()
                                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')}
                        </p>
                    </Grid>
                    {state.status_modal.toLowerCase() === 'lunas' ? (
                        <Grid
                            item
                            xs={12}
                            className="d-flex justify-content-between"
                        >
                            <button
                                className="w-100 rounded-6 border-bottom-red text-red py-8px bg-lightred mr-2"
                                onClick={() =>
                                    setState((prev) => ({
                                        ...prev,
                                        open: false
                                    }))
                                }
                            >
                                Tutup
                            </button>

                            <button
                                disabled={state.disabled}
                                className={`w-100 rounded-6 py-8px ml-2 ${
                                    state.disabled
                                        ? 'border-bottom-grey text-semiblack bg-light-grey'
                                        : 'border-bottom-blue text-blue bg-lightblue'
                                }`}
                                onClick={() => {
                                    setState((prev) => ({
                                        ...prev,
                                        disabled: true
                                    }));
                                    getDetailGaji(state.id).then((res) => {
                                        const data = res.data.data;

                                        history.push({
                                            pathname: '/pdf_link_gaji',
                                            state: { url: data.pdf_path }
                                        });
                                    });
                                }}
                            >
                                Unduh Slip Gaji
                            </button>
                        </Grid>
                    ) : (
                        <Grid
                            item
                            xs={12}
                            className="d-flex justify-content-between"
                        >
                            <button
                                className="w-100 rounded-6 text-abu-tua padblock-8 border-1-grey"
                                onClick={() =>
                                    setState((prev) => ({
                                        ...prev,
                                        open: false
                                    }))
                                }
                            >
                                Tutup
                            </button>
                        </Grid>
                    )}
                </Grid>
            </Dialog>
            <div>
                <MobileNavigation />
            </div>
        </div>
    );
};

export default GajiIndex;
