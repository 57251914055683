import { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DateRangePicker } from 'rsuite';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { API, setAuthToken } from '../../config/Api';
import { getListReimburse } from '../../store/actions/reimburseAction';
import { Button, Grid, TextField, InputAdornment } from "@material-ui/core";

import Title from '../../components/Title/Title';
import '../../assets/style/mobile-size.css';
import Select from '../../components/Input/Select';
import InputFileImg from '../../components/Input/InputFileImg';

const ReimburseAjukan = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { beforeToday } = DateRangePicker;

    const { listReimburse } = useSelector((state) => state.reimburse);

    const [state, setState] = useState({
        bukti_img: '',
        file_preview: '',
        jenis_izin: '',
        nominal: '',
        keterangan: '',
        
    });


    useLayoutEffect(() => {
         let params = ``;
        dispatch(getListReimburse(params));
    }, []);

    useEffect(() => {
        if (listReimburse !== []) {
            const data = listReimburse.map((item) => ({
                value: item.jenis_reimburse_code,
                text: item.jenis_reimburse
            }));

            setState((prev) => ({
                ...prev,
                listReimburse: data
            }));
        }
    }, [listReimburse]);


    const handleChangeImg = (file, path) => {
        setState({
            ...state,
            bukti_img: file,
            file_preview: path
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let object;

       

        object = {
            ...object,
            bukti: state.bukti_img,
            jenis: state.jenis_izin,
            nominal: state.nominal,
            keterangan: state.keterangan,

        };

        try {
            const token = localStorage.getItem('token');
            setAuthToken(token);
            API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
            API.post(   '/user/gaji/mobile/reimburse', object).then((res) => {
                const data = res.data.data;

                if (res.data.code === 2) {
                    Swal.fire({
                        width: '75%',
                        title: 'Error!',
                        text: data.msg,
                        icon: 'error'
                    });
                } else {
                    let timerInterval;
                    Swal.fire({
                        title: 'Sedang diproses...',
                        html: 'tunggu dalam waktu <b></b>.',
                        timer: 4000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading();
                            const b =
                                Swal.getHtmlContainer().querySelector('b');
                            setTimeout(() => {
                                clearInterval(timerInterval);
                                history.push('/reimburse');
                                Swal.fire({
                                    width: '75%',
                                    title: 'Success!',
                                    text: 'Reimburse berhasil diajukan',
                                    icon: 'success'
                                });
                            }, 4000);
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft();
                            }, 1000);
                        }
                    });
                }
            });
        } catch (e) {
            Swal.fire('Oopss!', 'Reimburse gagal diajukan', 'error');
        }
    };

    const title = (
        <div
            onClick={() => history.push('/izin')}
            className="d-flex align-items-center"
        >
            <i className="ri-arrow-left-line"></i>
            <p className="mx-2 my-auto">Ajukan Reimburse</p>
        </div>
    );

    const handleChange = (id) => (e) => {
    const { value } = e.target;

    setState((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };


    return (
        <div className="i-ultra-container">
            <form onSubmit={handleSubmit}>
                <Title title={title} />
                <div className="bg-white mb-5 px-4 mt-88">
                    <div className="mt-3">
                        <p className="fs-12 color-semiblack mb-2">Jenis Reimburse</p>
                        <Select
                            dataSelect={state.listReimburse}
                            state={state}
                            setState={setState}
                            label="Pilih Jenis Reimburse"
                            name="jenis_izin"
                            width="100%"
                        />
                    </div>

                     <div className="mt-3">
                        <p className="fs-12 color-semiblack mb-2">Nominal</p>
                        <TextField
                size='small'
                fullWidth
                variant="outlined"
                id="mui-theme-provider-outlined-input"
                value={state.nominal}
                onChange={handleChange("nominal")}
                name="nominal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <p className="color-semiblack fs-12">Rp</p>
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
              />
                    </div>
                   
                    <div className="mt-4">
                        <p className="fs-12 color-semiblack mb-2">Keterangan</p>
                        <textarea
                            name="keterangan"
                            rows="5"
                            onChange={handleChange('keterangan')}
                            cols="100"
                            className="form-control w-full py-14 px-16 fs-12"
                            placeholder="Masukkan keterangan reimburse"
                        />
                    </div>
                    <div className="mt-4 mb-70">
                        <p className="fs-12 color-semiblack mb-2">Bukti</p>

                        <InputFileImg
                            name="bukti_img"
                            uploadFoto={handleChangeImg}
                            preview={state.file_preview}
                            acceptFile="image"
                            noPadding={true}
                        />
                    </div>
                </div>
                <div className="my-2 fixed-bottom px-4 ajukan-btn-container">
                    <button
                        type="submit"
                        className="btn w-100 mx-auto text-white izin-ajukan-btn"
                        style={{ background: '#00acee' }}
                    >
                        Ajukan Reimburse
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ReimburseAjukan;
