import { Card, CardContent, Grid } from '@material-ui/core';
import '../../assets/style/mobile-size.css';

export default function CardIzinCuti({
    id,
    name,
    tanggal_gajian,
    unit_kerja,
    total_gaji,
    status,
    gaji_pokok,
    tambahan,
    pengurangan,
    setState,
    handleTgl,
    reimburse,
    jenis,
    keterangan
}) {
    let currentStatus = {};

    if (status === 'belum lunas' || status === 'Diproses') {
        currentStatus = {
            bgStatus: 'bg-warning',
            txtStatus: status,
            txtColor: 'text-warning'
        };
    } else if (status === 'lunas' || status === 'Diterima') {
        currentStatus = {
            bgStatus: 'bg-success',
            txtStatus: status,
            txtColor: 'text-success'
        };
    }

    else if ( status === 'Ditolak') {
        currentStatus = {
            bgStatus: 'bg-danger',
            txtStatus: status,
            txtColor: 'text-danger'
        };
    }

    const handleOpen = async () => {
        const name = await localStorage.getItem('username');

        setState((prev) => ({
            ...prev,
            status_modal: status,
            unit_kerja,
            id,
            name,
            jenis,
            tanggal_gajian,
            total_gaji,
            gaji_pokok,
            tambahan,
            pengurangan,
            keterangan: keterangan,
            open: true
        }));
    };

    return (
        <Card className=" my-3 mx-3 i-card px-2" key={id} onClick={handleOpen}>
            <CardContent>
                <Grid
                    container
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs={6}>
                        <p className="text-abu-muda fs-13 ">Nama Pegawai</p>
                        <p className="fs-14 text-black font-weight-medium">
                            {name}
                        </p>
                    </Grid>

                    {reimburse ? (<Grid item xs={6}>
                        <p className="text-abu-muda fs-13 ">Jenis</p>
                        <p className="fs-14 text-black font-weight-medium">
                            {jenis}
                        </p>
                    </Grid>): (
  <Grid item xs={6}>
                        <p className="text-abu-muda fs-13 ">Tanggal Gajian</p>
                        <p className="fs-14 text-black font-weight-medium">
                            {handleTgl(tanggal_gajian)}
                        </p>
                    </Grid>
                    )}

                  
                    <Grid item xs={6}>
                        <p className="text-abu-muda fs-13 ">Unit Kerja</p>
                        <p className="fs-14 text-black font-weight-medium">
                            {unit_kerja}
                        </p>
                    </Grid>
                    <Grid item xs={6}>
                        <p className="text-abu-muda fs-13 ">{reimburse ? "Nominal" : "Total Gaji" }</p>
                        <p className="fs-14 text-black font-weight-medium">
                            Rp.{' '}
                            {total_gaji
                                .toString()
                                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')}
                        </p>
                    </Grid>
                    <Grid item xs={6}>
                        <p className="fs-12 text-abu-muda mb-1">Status</p>
                        <div
                            className={`${currentStatus.bgStatus} rounded-lg d-flex justify-content-center`}
                            style={{ width: '100px' }}
                        >
                            <p className="mx-2 fs-12 my-2 text-white">
                                {currentStatus.txtStatus}
                            </p>
                        </div>
                    </Grid>
                    { reimburse && (
                         <Grid item xs={6}>
                        <p className="text-abu-muda fs-13 ">Dibuat Tanggal </p>
                        <p className="fs-14 text-black font-weight-medium">
                            {handleTgl(tanggal_gajian)}
                        </p>
                    </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
}
