import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Typography,
    AccordionDetails,
    Accordion,
    AccordionSummary
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Title from '../../components/Title/Title';
import '../../assets/style/akunIndex.css';
import '../../assets/style/ubahProfile.css';
import { getFaq } from '../../store/actions/akunAction';

const FAQ = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { faq } = useSelector((state) => state.akun);

    console.log(faq);

    useEffect(() => {
        dispatch(getFaq());
    }, []);

    const title = (
        <div onClick={() => history.push('/akun')} className="d-flex">
            <i className="ri-arrow-left-line"></i>
            <p className="mx-2">FAQ</p>
        </div>
    );
    return (
        <div className="i-ultra-container mx-auto p-0">
            <Title title={title} />
            <div class="w-100 mt-60">
                {faq === [] ? (
                    <div className="mx-auto my-8 text-center">
                        data FAQ kosong
                    </div>
                ) : (
                    faq.map((data) => (
                        <Accordion
                            className="py-1 px-2"
                            elevation={0}
                            key={data.faq_code}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{data.pertanyaan}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>{data.jawaban}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))
                )}
            </div>
        </div>
    );
};

export default FAQ;
