import { FileCopy, Wallpaper } from '@material-ui/icons';
import React, { useState } from 'react';
import Swal from 'sweetalert2';

const InputFileImg = ({
    uploadFoto,
    acceptFile,
    maxSize,
    preview,
    noPadding = false
}) => {
    const [FileName, setFileName] = useState(null);
    const FileType = {
        pdf: 'application/pdf',
        image: 'image/*, image/jpg, image/jpeg, image/png',
        doc: '.doc, .docx',
        pdfImg: 'application/pdf, image/*, image/jpg, image/jpeg',
        ppt: '.ppt, .pptx'
    };
    let fileInput = React.createRef();

    const getExtension = (filename) => {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    };

    const checkImage = (filename) => {
        var ext = getExtension(filename);
        if (acceptFile === 'pdf') {
            switch (ext.toLowerCase()) {
                case 'pdf':
                    return true;
                default:
                    return false;
            }
        } else if (acceptFile === 'image') {
            switch (ext.toLowerCase()) {
                case 'jpg':
                case 'png':
                case 'jpeg':
                    return true;
                default:
                    return false;
            }
        } else if (acceptFile === 'doc') {
            switch (ext.toLowerCase()) {
                case 'doc':
                case 'docx':
                    return true;
                default:
                    return false;
            }
        } else if (acceptFile === 'pdfImg') {
            switch (ext.toLowerCase()) {
                case 'jpg':
                case 'jpeg':
                case 'pdf':
                    return true;
                default:
                    return false;
            }
        } else if (acceptFile === 'ppt') {
            switch (ext.toLowerCase()) {
                case 'ppt':
                case 'pptx':
                    return true;
                default:
                    return false;
            }
        }
    };

    const kbConverter = (size) => {
        return size * 1024;
    };

    const handleImageChange = (e) => {
        let files = e.target.files || e.dataTransfer.files;

        if (!files.length) return;
        if (checkImage(e.target.files[0].name)) {
            let file = files[0];
            let reader = new FileReader();
            if (maxSize && kbConverter(maxSize) < file.size) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Error',
                    text: 'the file is too large',
                    showConfirmButton: false,
                    timer: 1500
                });
                return;
            }
            reader.readAsDataURL(file);
            const initFile = URL.createObjectURL(file);
            setFileName(file.name);
            reader.onloadend = () => {
                uploadFoto(reader.result, initFile);
            };
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Error',
                text: 'Invalid File Format',
                showConfirmButton: false,
                timer: 1500
            });
        }
    };
    return (
        <div className={noPadding ? '' : 'wrap-border-file'}>
            <div className="_input-file d-flex align-items-center justify-content-center flex-column position-relative">
                {preview ? (
                    <div className="container-preview w-100">
                        <img
                            src={preview}
                            alt="preview"
                            className="w-100 h-100 img-preview"
                        />
                    </div>
                ) : (
                    <div className="content"></div>
                )}
                <div
                    className={`position-absolute d-flex flex-column justify-content-center align-items-center h-100 w-100 ${
                        preview ? 'preview-active' : ''
                    }`}
                >
                    {acceptFile === 'image' ? (
                        <Wallpaper color="disabled" fontSize="large" />
                    ) : (
                        <FileCopy color="disabled" fontSize="large" />
                    )}
                    <span className="placeholder-file">
                        {FileName ? FileName : 'Drag and drop here'}
                    </span>
                    <input
                        type="file"
                        name="foto"
                        onChange={handleImageChange}
                        ref={fileInput}
                        accept={FileType[acceptFile]}
                    />
                </div>
            </div>
        </div>
    );
};

export default InputFileImg;
