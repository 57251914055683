import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { IlNotFound } from '../../assets';

const NotFound = () => {
    useEffect(() => {
        document.title = 'AlanHR | Not Found';
    }, []);
    return (
        <Container className=" d-flex align-items-center flex-column container-not-found">
            <img
                src={IlNotFound}
                alt="ilustration not found"
                className="w-100"
            />
            <Link
                className="btn btn-back mt-4 radius-8 text-white px-4 py-2 rounded-silinder border-0"
                to="/"
            >
                Back to Homepage
            </Link>
        </Container>
    );
};

export default NotFound;
