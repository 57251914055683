import { useHistory } from 'react-router-dom';
import Title from '../../components/Title/Title';
import React, { useState } from 'react';
import '../../assets/style/ubahProfile.css';
import '../../assets/style/akunIndex.css';
import { updateKataSandiMobile } from '../../store/actions/akunAction';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import {
    IconButton,
    InputAdornment,
    TextField
} from '@material-ui/core';
import {
    IcEyeClose,
    IcEyeOpen,
    IcPass
} from '../../assets';

const UbahKataSandi = () => {
    const [formData, setFormData] = useState({
        kata_sandi_lama: '',
        kata_sandi_baru: '',
        konfirmasi_kata_sandi: ''
    });

    const history = useHistory();

    const handleChange = ({ target: { value, name } }) => {
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    // const { id } = localStorage.getItem('users_code')

    const dispatch = useDispatch();

    const handleSubmit = () => {
        console.log(formData);
        dispatch(
            updateKataSandiMobile({
                // users_code: id,
                kata_sandi_lama: formData.kata_sandi_lama,
                kata_sandi_baru: formData.kata_sandi_baru,
                kata_sandi_baru_confirmation:
                    formData.kata_sandi_baru_confirmation
            })
        )
            .then(() => {
                Swal.fire('Success!', 'Data user berhasil disimpan', 'success');
                history.push('/akun');
            })
            .catch((err) => {
                let error = err?.response?.data;
                console.log(error);
                Swal.fire(
                    Array.isArray(error?.data)
                        ? error?.data[0]
                        : error?.data.kata_sandi_baru[0],
                    false
                );
            });
    };

    const title = (
        <div onClick={() => history.push('/akun')} className="d-flex">
            <i className="ri-arrow-left-line"></i>
            <p className="mx-2">Ubah Kata Sandi</p>
        </div>
    );

    // password
    const [showPass, setshowPass] = useState(false);


    return (
        <div className="i-ultra-container mx-auto p-0">
            <Title title={title} />
            <div class="container mt-5">
                <div class="starter-margin">
                    <p>
                        <small>Kata Sandi Lama</small>
                    </p>
                    <div class="input-group mb-3">
                        <TextField
                            variant="outlined"
                            placeholder="Kata Sandi Lama"
                            onChange={handleChange}
                            name="kata_sandi_lama"
                            validators={['required']}
                            errorMessages={['this field is required']}
                            className="w-100 mb-3"
                            type={showPass ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                setshowPass(!showPass)
                                            }
                                        >
                                            <img
                                                alt="password"
                                                src={
                                                    showPass
                                                        ? IcEyeClose
                                                        : IcEyeOpen
                                                }
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </div>
                </div>

                <div>
                    <p>
                        <small>Kata Sandi Baru</small>
                    </p>
                    <div class="input-group mb-3">
                        <TextField
                            variant="outlined"
                            placeholder="Kata Sandi Baru"
                            onChange={handleChange}
                            name="kata_sandi_baru"
                            validators={['required']}
                            errorMessages={['this field is required']}
                            className="w-100 mb-3"
                            type={showPass ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                setshowPass(!showPass)
                                            }
                                        >
                                            <img
                                                alt="password"
                                                src={
                                                    showPass
                                                        ? IcEyeClose
                                                        : IcEyeOpen
                                                }
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </div>
                </div>

                <div>
                    <p>
                        <small>Konfirmasi Kata Sandi</small>
                    </p>
                    <div class="input-group mb-3">
                        <TextField
                            variant="outlined"
                            placeholder="Konfirmasi Kata Sandi"
                            onChange={handleChange}
                            name="kata_sandi_baru_confirmation"
                            validators={['required']}
                            errorMessages={['this field is required']}
                            className="w-100 mb-3"
                            type={showPass ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                setshowPass(!showPass)
                                            }
                                        >
                                            <img
                                                alt="password"
                                                src={
                                                    showPass
                                                        ? IcEyeClose
                                                        : IcEyeOpen
                                                }
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        {/* <input
                            type="text"
                            class="form-control"
                            placeholder="Konfirmasi Kata Sandi"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            name="kata_sandi_baru_confirmation"
                            onChange={handleChange}
                        ></input> */}
                    </div>
                </div>
                <button
                    type="button"
                    class="btn btn-primary btn-block mt-4 text-white btn-simpan"
                    onClick={handleSubmit}
                >
                    Simpan
                </button>
            </div>
        </div>
    );
};

export default UbahKataSandi;
